import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms'

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace = (control?.value || '').toString().trim().length === 0
    const isValid = !isWhitespace
    return (isValid ? null : { required: true })
  }
}

export function betterEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = (control?.value || '').toString().trim().toLowerCase()
    const isEmpty = value.length === 0
    const isValid = emailRegex.test(value)
    return isValid || isEmpty ? null : { email: true }
  }
}
export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? {forbiddenName: {value: control.value}} : null;
  };
}

const emailRegex = new RegExp('^[a-z0-9\._%+-]+@([a-z0-9.-]+\.)+[a-z]{2,4}$')
