import { Component, OnInit, Input, ElementRef, HostListener, ViewChild, AfterViewInit, Output, EventEmitter,OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ContainerMeasurements, DisplayMode, ThreeSixtyConfig } from './three-sixty-config.model';
import { WebpsupportService } from './webpsupport.service';


@Component({
  selector: 'letink-three-sixty-slider',
  templateUrl: './three-sixty-slider.component.html',
  styleUrls: ['./three-sixty-slider.component.scss']
})
export class ThreeSixtySliderComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, OnChanges {

  //constants
  private MOUSE_SCROLL_SPEED: number = 1 / 500;
  private TOUCH_PINCH_SPEED: number = 8;

  //loading states
  //the container where the images will be placed
  @ViewChild("imageContainer") imageContainer: ElementRef;
  private isDestroyed: boolean = false;
  private isInitialized: boolean = false;

  //webp support
  private isWebPTested: boolean = false;
  private triggerInitAfterWebPResult: boolean = false;
  private hasWebPSupport: boolean = false;

  //image preloading
  private imgRoot: HTMLElement = undefined;
  private toLoad = [];
  private loadedCount: number = 0;

  //slider state
  //current images, all frames in a double array
  //frames[y][x] gives image component
  private frames = [];
  private currentX: number = 0;
  private currentY: number = 0;

  //mouse/touch state
  private isTouchDevice: boolean = false;
  private isDragging: boolean = false;
  private isDown: boolean = false;
  private isZooming: boolean = false;
  private startXMousePos: number = undefined;
  private startYmousePos: number = undefined;
  private startXIndex: number = undefined;
  private startYIndex: number = undefined;
  private currentXmousePos: number = undefined;
  private currentYmousePos: number = undefined;
  private lastMultiTouchDistance: number = undefined;

  //sizing
  private SCREEN_DIAGONAL: number = 0;
  private containerWidth: number = 0;
  private containerHeight: number = 0;

  //animation
  private tickID: any = undefined;
  private animate_towards_x: number = 0;
  private animate_towards_y: number = 0;
  private animating_towards_frame: boolean = false;
  private animating_frameoffset: number = 0;


  //public communication
  //  early setup
  @Input("container-class") containerclass: string = "";
  @Input() config: ThreeSixtyConfig = undefined;

  //  loading
  @Input() autoInit: boolean = true;
  @Output() onLoadingPercentageChange = new EventEmitter<number>();
  @Output() onImagesLoaded = new EventEmitter<boolean>();

  //  global state
  @Input() enabled: boolean = true;
  @Output() onRecalculateSizing = new EventEmitter<ContainerMeasurements>();

  //  runtime functions
  @Input("zoom") currentZoom: number = 1;
  @Output("zoomChange") zoomChange = new EventEmitter<number>();

  @Input() autoplay: boolean = false;
  @Output() autoplayChange = new EventEmitter<boolean>();

  @Output() x = new EventEmitter<number>();
  @Output() y = new EventEmitter<number>();

  @Output() onDownStateChange = new EventEmitter<boolean>();

  @Output() onAnimating = new EventEmitter<boolean>();



  //            PUBLICS

  public applyConfig(config: ThreeSixtyConfig) {
    this.config = config;

    //this means we're not done yet, but we're already changing the config
    //the slider is not ready to initialize, and because this is a slider
    //without auto-init, it will never trigger to init, so manually set a flag
    //to initialize after webp test is done
    if (!this.autoInit && !this.isWebPTested)
      this.triggerInitAfterWebPResult = true;
    else if (this.isWebPTested)
      this.initConfig();

    //config changed, maybe the fps changed as well
    this.stopTicks();
    if (this.autoplay)
      this.startTicks();
  }

  public animateTowards(x: number, y: number, frame_offset: number) {
    this.animating_towards_frame = true;
    this.animate_towards_x = x;
    this.animate_towards_y = y;
    this.animating_frameoffset = frame_offset;

    this.startTicks();

    this.onAnimating.emit(true);
  }

  //resets the view to the start frame
  public reset() {
    this.currentX = this.config.startindex_x;
    this.currentY = this.config.startindex_y;
    this.stopAutoplay();
    this.x.emit(this.currentX);
    this.displayFrame();

  }

  public showFrame(x: number, y: number) {
    this.currentX = x;
    this.currentY = y;
    this.x.emit(this.currentX);
    this.displayFrame();
  }





  //            INIT


  constructor(private elRef: ElementRef, private webpTester: WebpsupportService) {

    if (this.webpTester.isTested()) {
      this.hasWebPSupport = this.webpTester.isSupported();
      this.isWebPTested = true;
    } else {
      this.webpTester.onTested.subscribe((result) => {
        this.hasWebPSupport = result;
        this.isWebPTested = true;

        if (this.autoInit && !this.isInitialized)
          this.initConfig();
        //below is triggered if autoplay is false, but someone called applyConfig() manually
        //before the webP tester was done
        else if (this.config != undefined && this.triggerInitAfterWebPResult)
          this.initConfig();
      });
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    let waitForWebP = false;

    if (this.autoInit && !this.isInitialized && this.config != undefined) {
      waitForWebP = this.config.image_filename_extension == ".webp";
      if ((!waitForWebP || this.isWebPTested)) {
        this.initConfig();
      }
    }
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    this.removeEventListeners();
    this.stopAutoplay();

    let imgRoot = <HTMLElement>this.imageContainer.nativeElement;
    //clear existing
    while (imgRoot.firstChild) {
      imgRoot.removeChild(imgRoot.lastChild);
    }
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event?) {
    this.styleHost();

    let hostEl = <HTMLElement>this.elRef.nativeElement;
    let containerWidth = hostEl.offsetWidth;
    let containerHeight = hostEl.offsetHeight;

    this.SCREEN_DIAGONAL = Math.hypot(containerWidth, containerHeight);
  }

  private initConfig() {

    if (this.isInitialized)
      this.removeEventListeners();

    this.currentX = this.config.startindex_x;
    this.currentY = this.config.startindex_y;

    this.x.emit(this.currentX);
    this.y.emit(this.currentY);

    this.onResize();
    this.preload();
    this.addEventListeners();
    this.setZoom(this.currentZoom);

    this.isInitialized = true;
  }

  private addEventListeners() {

    this.isTouchDevice = !!('ontouchstart' in window);
    let targetElement = (<HTMLElement><HTMLElement>this.imageContainer.nativeElement).parentElement;
    targetElement.addEventListener('touchstart', this._mouseDown.bind(this));
    targetElement.addEventListener('mousedown', this._mouseDown.bind(this));

    targetElement.addEventListener('touchmove', this._mouseMove.bind(this));
    targetElement.addEventListener('mousemove', this._mouseMove.bind(this));

    targetElement.addEventListener('touchend', this._mouseUp.bind(this));
    targetElement.addEventListener('mouseup', this._mouseUp.bind(this));

    targetElement.addEventListener('mouseleave', this._mouseLeave.bind(this));

    targetElement.addEventListener("wheel", this._mouseScroll.bind(this));
  }

  private removeEventListeners() {

    let targetElement = (<HTMLElement><HTMLElement>this.imageContainer.nativeElement).parentElement;
    targetElement.removeEventListener('touchstart', this._mouseDown.bind(this));
    targetElement.removeEventListener('mousedown', this._mouseDown.bind(this));

    targetElement.removeEventListener('touchmove', this._mouseMove.bind(this));
    targetElement.removeEventListener('mousemove', this._mouseMove.bind(this));

    targetElement.removeEventListener('touchend', this._mouseUp.bind(this));
    targetElement.removeEventListener('mouseup', this._mouseUp.bind(this));

    targetElement.removeEventListener('mouseleave', this._mouseLeave.bind(this));

    targetElement.removeEventListener("wheel", this._mouseScroll.bind(this));
  }



  //        LOGIC

  //        -- change detection

  ngOnChanges(changes: SimpleChanges) {

    if (changes.autoplay != undefined) {
      if (changes.autoplay.currentValue === true) {
        this.startTicks();
      }
      else if (changes.autoplay.currentValue === false)
        this.stopTicks();
    }

    if (changes.currentZoom != undefined) {
      this.applyZoomValueToSlider();
    }
  }


  //        -- Generic animations
  private startTicks() {

    if (this.config) {
      this.tickID = setInterval(() => { this.doTickTasks() }, 1000 * (1 / this.config.autoplay_speed));
    }
  }
  private stopTicks() {
    if (this.tickID) {
      clearInterval(this.tickID);
    }
  }

  private doTickTasks() {
    if (this.autoplay) {
      this.handleTickAutoplay();
    }

    if (this.animating_towards_frame) {
      this.handleTickAnimation();
    }
  }


  //        -- Autoplay
  private stopAutoplay() {
    this.stopTicks();
    this.autoplay = false;
    this.autoplayChange.emit(false);
  }

  private handleTickAutoplay() {

    let newx = this.currentX;
    let newy = this.currentY;

    //autoplay only does x-axis
    //but check for looping and stop autoplay if we're not in a looping slider
    newx = this.currentX + 1;
    let clampedx = this.ClampOrWrapX(newx);
    let shouldPause = this.findPauseBetweenCurrentAndNewX(clampedx);

    if (!shouldPause) {
      this.currentX = clampedx;
    } else {
      if (this.autoplay)
        this.stopAutoplay();
    }

    if (this.currentX != newx) {
      //got clamped because we're at the end - stop autoplay
      this.stopAutoplay();
    } else {
      this.x.emit(this.currentX);
      this.displayFrame();
    }
  }


  //        -- Animation
  private handleTickAnimation() {

    let newx = this.currentX;
    let newy = this.currentY;

    if (this.currentX != this.animate_towards_x) {
      newx = this.currentX + this.animating_frameoffset;
      newx = this.ClampOrWrapX(newx);
    }

    if (this.currentY != this.animate_towards_y) {
      newy = this.currentY + this.animating_frameoffset;
      newy = this.ClampOrWrapY(newy);
    }

    if (this.currentX == newx && this.currentY == newy) {
      //at location!
      this.stopTicks();
      this.animating_towards_frame = false;
      this.onAnimating.emit(false);
    } else {

      this.currentX = newx;
      this.currentY = newy;

      this.x.emit(this.currentX);
      this.y.emit(this.currentY);
      this.displayFrame();
    }
  }


  //            -- preloading config
  private preload() {
    let ext = this.config.image_filename_extension;
    if (ext == ".webp" && !this.hasWebPSupport) {
      ext = this.config.webp_fallback;
    }

    this.loadedCount = -1;
    this.imgRoot = <HTMLElement>this.imageContainer.nativeElement;

    //clear existing
    while (this.imgRoot.firstChild) {
      this.imgRoot.removeChild(this.imgRoot.lastChild);
    }

    this.onLoadingPercentageChange.emit(0);

    this.frames = [];
    this.toLoad = [];

    for (let y = 0; y < this.config.image_count_y; y++) {

      let frames_x = [];

      for (let x = 0; x < this.config.image_count_x; x++) {

        let xnum = this.config.image_count_x_start + x;
        let ynum = this.config.image_count_y_start + y;

        let filename = this.config.image_path_prefix + this.config.image_filename_prefix + ynum + "_" + xnum + ext;

        if (this.config.single_numbering) {
          let num = (ynum * this.config.image_count_x) + xnum;


          let num_string = num + "";
          if (this.config.leading_zeroes) {
            while (num_string.length < this.config.number_string_length_leading_zeros)
              num_string = "0" + num_string;
          }

          filename = this.config.image_path_prefix + this.config.image_filename_prefix + num_string + ext;
        }

        this.toLoad.push({ x: x, y: y, src: filename });
        frames_x.push(undefined);

      }

      this.frames.push(frames_x);
    }
    this.imgLoaded();

  }

  private createImage(src: string, x: number, y: number) {
    let i = new Image();

    i.src = src;
    i.style.width = "100%";
    i.style.height = "100%";
    i.style.top = "0";
    i.style.left = "0";
    i.style.position = "absolute";
    // i.className = (x == this.x && y == this.y) ? "yes" : "no";
    i.className = "no";

    this.imgRoot.appendChild(i);
    i.onload = () => { this.imgLoaded(); };

    this.frames[y][x] = i;
  }

  private imgLoaded() {

    if (this.isDestroyed)
      return;

    //dev: delayed loading
    // setTimeout(() => {

    this.loadedCount++;
    let loadingPercentage = this.loadedCount / (this.config.image_count_x * this.config.image_count_y);
    this.onLoadingPercentageChange.emit(loadingPercentage);

    if (this.loadedCount >= (this.config.image_count_x * this.config.image_count_y)) {
      this.displayFrame();
      this.onImagesLoaded.emit(true);
    } else {
      //load next
      const next = this.toLoad.shift();
      this.createImage(next.src, next.x, next.y);
    }

    // }, 100);

  }


  //            -- events
  private _mouseScroll($event: WheelEvent) {
    if (this.config.allow_zoom) {
      let newZoom = this.currentZoom + $event.deltaY * this.MOUSE_SCROLL_SPEED;
      this.setZoom(newZoom);
    }
  }

  private _mouseDown($event: MouseEvent | TouchEvent) {
    if (this.animating_towards_frame)
      return;

    let multitouch = false;
    if (this.isTouchDevice) {
      const te = <TouchEvent>$event;
      if (te.touches.length == 2) {
        multitouch = true;
        this.isZooming = this.config.allow_zoom;
        if (this.isZooming)
          this.lastMultiTouchDistance = Math.hypot(
            te.touches[0].pageX - te.touches[1].pageX,
            te.touches[0].pageY - te.touches[1].pageY);
      }
    }

    if (!multitouch) {
      this.isDown = true;
      this.onDownStateChange.emit(true);
      $event.preventDefault();
    } else {
      this.isDown = false;
      this.onDownStateChange.emit(false);
    }

    if (this.isDown == true && this.autoplay) {
      //cancel autoplay when user moves
      this.stopAutoplay();
    }
  }

  private _mouseUp($event: MouseEvent | TouchEvent) {

    if (this.isDown && this.isDragging) {
      $event.preventDefault();
    }

    this.isDown = false;
    this.onDownStateChange.emit(false);
    this.isDragging = false;
  }

  private _mouseMove($event: MouseEvent | TouchEvent) {
    if (this.isDown && this.enabled && !this.animating_towards_frame) {

      if (!this.isDragging) {
        //init drag
        this.isDragging = true;
        this.fetchMousePositionFromEvent($event);
        this.startXMousePos = this.currentXmousePos;
        this.startYmousePos = this.currentYmousePos;
        this.startXIndex = this.currentX;
        this.startYIndex = this.currentY;
      } else {
        //init done - checking offset
        this.fetchMousePositionFromEvent($event);
        this.determineXYFromMouseDelta();
      }
      $event.preventDefault();
    }

    if (this.isZooming) {
      const te = <TouchEvent>$event;
      if (te.touches.length == 2) {
        let distance = Math.hypot(
          te.touches[0].pageX - te.touches[1].pageX,
          te.touches[0].pageY - te.touches[1].pageY);
        let delta = distance - this.lastMultiTouchDistance;
        this.lastMultiTouchDistance = distance;
        let newZoom = this.currentZoom + (delta / this.SCREEN_DIAGONAL) * this.TOUCH_PINCH_SPEED;
        this.setZoom(newZoom);

        $event.preventDefault();
      } else {
        this.isZooming = false;
      }
    }
  }

  private _mouseLeave($event) {
    if (this.isDown) {
      this.isDown = false;
      this.onDownStateChange.emit(false);
      this.isDragging = false;
      this.isZooming = false;
    }
  }


  // depending on touch or mouse, fetch the current touch or mouse position
  //from the event
  private fetchMousePositionFromEvent($event: TouchEvent | MouseEvent) {
    if (this.isTouchDevice) {
      let e = <TouchEvent>$event;

      this.currentXmousePos = e.changedTouches[0].pageX;
      this.currentYmousePos = e.changedTouches[0].pageY;
    }
    else {
      let e = <MouseEvent>$event;
      this.currentXmousePos = e.clientX;
      this.currentYmousePos = e.clientY;
    }
  }

  //determines and displays the frame
  //based on the delta mouse movement
  private determineXYFromMouseDelta() {
    let deltaX = this.startXMousePos - this.currentXmousePos;
    let deltaY = this.currentYmousePos - this.startYmousePos;

    let xPercentage = (deltaX / this.containerWidth) * this.config.x_speed;
    let yPercentage = (deltaY / this.containerHeight) * this.config.y_speed;

    let xIndexOffset = Math.round(xPercentage * this.config.image_count_x);
    let yIndexOffset = Math.round(yPercentage * this.config.image_count_y);

    let newX = this.startXIndex + xIndexOffset;

    //early out for performance
    if (newX == this.currentX && this.config.image_count_y == 1)
      return;

    newX = this.ClampOrWrapX(newX);

    let newY = this.startYIndex + yIndexOffset;
    newY = this.ClampOrWrapY(newY);

    let shouldPause = this.findPauseBetweenCurrentAndNewX(newX);

    if (!shouldPause) {
      this.currentX = newX;
      this.x.emit(this.currentX);
    } else {
      //stop possible autoplay
      if (this.autoplay)
        this.stopAutoplay();

      //stop the dragging - users can continue by releasing the mouse first and then drag again
      this.isDown = false;
      this.onDownStateChange.emit(false);
      this.isDragging = false;

    }
    this.currentY = newY;
    this.y.emit(this.currentY);

    this.displayFrame();
  }


  private findPauseBetweenCurrentAndNewX(newX: number) {
    let pauseFrameTriggered = false;
    if (this.config.pauses != undefined && this.config.pauses.length > 0) {

      this.config.pauses.forEach(pauseFrame => {

        if (!pauseFrameTriggered) {
          if (newX == pauseFrame) {
            this.currentX = pauseFrame;
            this.x.emit(this.currentX);
            pauseFrameTriggered = true;
          }

          else if (this.currentX > pauseFrame && newX < pauseFrame) {
            pauseFrameTriggered = true;
            this.currentX = pauseFrame;
            this.x.emit(this.currentX);
          }
          else if (newX > pauseFrame && this.currentX < pauseFrame) {
            pauseFrameTriggered = true;
            this.currentX = pauseFrame;
            this.x.emit(this.currentX);
          }
        }
      });
    }
    return pauseFrameTriggered;
  }

  private ClampOrWrapY(newY: number) {
    if (this.config.y_wrap) {
      while (newY >= this.config.image_count_y)
        newY -= this.config.image_count_y;
      while (newY < 0)
        newY += this.config.image_count_y;
    } else {
      if (newY < 0)
        newY = 0;
      else if (newY >= this.config.image_count_y)
        newY = this.config.image_count_y - 1;
    }
    return newY;
  }

  private ClampOrWrapX(newX: number) {
    if (this.config.x_wrap) {
      while (newX >= this.config.image_count_x)
        newX -= this.config.image_count_x;
      while (newX < 0)
        newX += this.config.image_count_x;
    } else {
      if (newX < 0)
        newX = 0;
      else if (newX >= this.config.image_count_x)
        newX = this.config.image_count_x - 1;
    }
    return newX;
  }

  private displayFrame() {

    let el = <HTMLImageElement>(this.frames[this.currentY][this.currentX]);
    el.className = "yes";

    for (let y = 0; y < this.config.image_count_y; y++) {
      for (let x = 0; x < this.config.image_count_x; x++) {

        let el = <HTMLImageElement>(this.frames[y][x]);
        el.className = (x == this.currentX && y == this.currentY) ? "yes" : "no";
      }
    }
  }


  private setZoom(newZoom: number) {

    if (newZoom < 1)
      newZoom = 1;
    if (newZoom > this.config.zoom_max)
      newZoom = this.config.zoom_max;

    if (newZoom != this.currentZoom) {
      this.currentZoom = newZoom;
      this.zoomChange.emit(this.currentZoom);
    }

    this.applyZoomValueToSlider();
  }

  private applyZoomValueToSlider() {
    //ngOnChanges might call this before the element is initialized
    //doesn't matter, we can skip for now - the zoom-apply
    // is also called after initialization
    if (this.imageContainer == undefined)
      return;

    let targetElement = <HTMLElement>this.imageContainer.nativeElement;
    targetElement.style.transform = "scale(" + this.currentZoom + ")";
  }


  private styleHost() {
    if (this.config == undefined)
      return;

    let size = new ContainerMeasurements();

    let hostEl = <HTMLElement>this.elRef.nativeElement;

    let containerWidth = hostEl.offsetWidth;
    let containerHeight = hostEl.offsetHeight;

    //set either width or height explicit, based on the current container width or height
    //to keep aspect of image and container filled
    let imageAspect = this.config.image_width / this.config.image_height;
    let screenAspect = containerWidth / containerHeight;

    let width = "100%";
    let height = "100%";

    if (this.config.display_mode == DisplayMode.Contain) {
      if (imageAspect > screenAspect) {
        height = (containerWidth / imageAspect) + "px";
      } else {
        width = (containerHeight * imageAspect) + "px";
      }
    } else {
      if (imageAspect > screenAspect) {
        width = (containerHeight * imageAspect) + "px";
      } else {
        height = (containerWidth / imageAspect) + "px";
      }

    }

    this.imageContainer.nativeElement.style.width = width;
    this.imageContainer.nativeElement.style.height = height;

    size.width = width;
    size.height = height;
    size.top = "";
    size.left = "";

    if (this.config.display_mode == DisplayMode.Contain) {
      //vertical centering
      if (imageAspect > screenAspect) {
        //center height
        let leftover = containerHeight - (containerWidth / imageAspect);
        this.imageContainer.nativeElement.style.top = (leftover / 2) + "px";
        this.imageContainer.nativeElement.style.left = "";

        this.containerWidth = containerWidth;
        this.containerHeight = (containerWidth / imageAspect);

      } else {

        let leftover = containerWidth - (containerHeight * imageAspect);
        this.imageContainer.nativeElement.style.left = (leftover / 2) + "px";
        this.imageContainer.nativeElement.style.top = "";

        this.containerWidth = (containerHeight * imageAspect);
        this.containerHeight = containerHeight;
      }
    } else {
      if (imageAspect > screenAspect) {
        this.containerWidth = containerHeight * imageAspect;
        this.containerHeight = containerHeight;
        let overflow = containerWidth - this.containerWidth;
        this.imageContainer.nativeElement.style.left = (overflow / 2) + "px";
        this.imageContainer.nativeElement.style.top = "";
      } else {
        this.containerWidth = containerWidth;
        this.containerHeight = containerWidth / imageAspect;
        let overflow = containerHeight - this.containerHeight;
        this.imageContainer.nativeElement.style.top = (overflow / 2) + "px";
        this.imageContainer.nativeElement.style.left = "";

      }
    }

    size.top = this.imageContainer.nativeElement.style.top;
    size.left = this.imageContainer.nativeElement.style.left;

    this.onRecalculateSizing.emit(size);
  }
}
