import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../services/analytics.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit, OnDestroy {

  body: SafeHtml
  sub? : Subscription;

  @Output() onUserMadeAChoice = new EventEmitter();

  constructor(private translate : TranslateService, private sanitizer : DomSanitizer, private analytics : AnalyticsService) {
    this.sub = translate.onLangChange.subscribe((ev:LangChangeEvent)=>{
      this.setBody();
    })
    this.setBody();
   }

   ngOnDestroy(): void {
     if(this.sub != undefined)this.sub.unsubscribe();
   }

   setBody(){
    this.body = this.sanitizer.bypassSecurityTrustHtml(
      this.translate.instant('cookie-consent.popup-body')
    )
   }

   clickedRefuse(){
    this.analytics.userConsentAction(false);
    this.onUserMadeAChoice.emit();
   }
   clickedAccept(){
    this.analytics.userConsentAction(true);
    this.onUserMadeAChoice.emit();
   }

  ngOnInit(): void {
  }

}
