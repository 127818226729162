
import * as THREE from 'three';
import { Clickable } from './tour';

export class PanoViewerConfig {

    //Speed in which we orbit inside the panorama. This is a multiplier
    //so 1 = base speed (based on field of view of the camera)
    //and 2 = twice as fast.
    orbitSpeed?: number = 1;

    transitionTime? : number = 0.5;

    //List of UI stylings for clickable elements
    clickableMaterials: ClickableMaterial[] = [];

    fontData?: FontData = new FontData();

    fov?: number = 90;

    public static build(config: PanoViewerConfig): PanoViewerConfig {
        //we need to use Object.assign to merge a default config with the user-given
        //config.
        //This will result in a config where every property exists with its default
        //value, but also overridden with a users value.
        //so the user doesn't have to fill in everything, just the parts he wants to change


        //also object assign fontdata
        //dont object assign clickable material - those don't have optional params
        const obj = Object.assign(new PanoViewerConfig(), config);
        obj.fontData = FontData.build(config.fontData);

        return obj;
    }
}


export class ClickableUI {
    clickable : Clickable

    onClick: OnClickEvent;

    mesh?: THREE.Mesh = null;
    mixer?: THREE.AnimationMixer = null;
}

export class ClickableMaterial {
    id: string;

    width: number;
    height: number;
    image: string;

    //cached values
    texture?: THREE.Texture;
    material?: THREE.Material;

}

export class FontData {
    fontTTF? : string = "./assets/fonts/Inter-Regular.ttf"
    fontSize?: number = 0.1;
    fontSizeCenter?: number = 0.1;
    centerY? : number = 0.12
    fontColor?: THREE.Color = new THREE.Color(0xFFFFFF)
    fontColorCenter?: THREE.Color = new THREE.Color(0xFFFFFF)
    outlineColor?: THREE.Color = new THREE.Color(0x000000)
    outlineWidth? :number = 0.01
    outlineBlur? : string ="10%";
    outlineOpacity? : number =0.5

    public static build(fontData: FontData): FontData {
        return Object.assign(new FontData(), fontData);
    }
}

export type OnClickEvent = () => any;
export type OnDoneLoadingEvent = () => any;