<!-- drag image -->
<app-panner [content]="content" #panner class="full-frame container" start-zoom="-1" [max-zoom]="isPhone?1:2"  
[margin]="{left:0,top:0,right:SIDEBAR_WIDTH_PX, bottom:0}">
    <div class="content" #content [ngStyle]="image_container_style">

    </div>

</app-panner>

<app-sidebar [start-open]="sidebarStartOpen" #sidebar  stretch="true" (state-change)="OnSidebarStateChange($event)">
    <h2 translate>farm-selection.sidebar-header</h2>
    <div class="flow">

        <div class="group">
            <h3 translate>main.select-language</h3>
            <app-ui-dropdown [dropdown-data]="languageDropdownData" [(value)]="selectedLanguage"
            class="fixed-width rounded lang" (valueChange)="onLangChanged($event)"></app-ui-dropdown>
        </div>
        
        <div class="group">
            <h3 translate>farm-selection.herdsize-header</h3>
            <div class="button-grid">
                <a class="rounded center large" *ngFor="let size of herdSizes" [ngClass]="{'white': size == herdSize}"
                    (click)="clickedHerdSize(size)">
                    {{size}}
                </a>
            </div>
        </div>

        <ng-container *ngIf="layouts.length > 0">
            <div class="group">
                <h3 translate>farm-selection.layout-header</h3>
                <div class="button-grid">
                    <a class="rounded center large" *ngFor="let l of layouts" [ngClass]="{'white': l == layout}"
                        (click)="clickedLayout(l)">
                        {{l}}
                    </a>
                </div>
            </div>
        </ng-container>

    </div>

    <div class="action-row">
        <a class="rounded large green idle-wobble" [routerLink]="url" *ngIf="!isPhone">
            <span translate>main.navigation.forward.floorplan</span>
            <img src="./assets/ui/icons/icon-arrow-right-black.svg">
        </a>
        
        <a class="rounded large green" (click)="sidebar.close()" *ngIf="isPhone">
            <span translate>
                main.navigation.mobile.view-farm
            </span>
            <img src="./assets/ui/icons/icon-arrow-right-black.svg">
        </a>
    </div>
</app-sidebar>

<app-navigatorbar [bar-open]="sidebar != undefined && sidebar.isOpen()"
backTranslate="{{isPhone ? 'main.navigation.backwards.overview':''}}"
forwardTranslate="main.navigation.forward.floorplan"
(backClicked)="backClickedNavigator()"
(forwardClicked)="forwardClickedNavigator()">
</app-navigatorbar>

<img class="gea-logo" src="./assets/ui/logos/gea-logo-payoff-white.svg" *ngIf="!isPhone">
<img class="gea-logo" src="./assets/ui/logos/gea-logo-no-payoff.svg" *ngIf="isPhone">


<ng-container *ngFor="let hint of hints; let index = index">
    <app-hint class="hidden" [appFadeVisible]="index == hintIndex" [image]="hint.image" [text]="hint.text"
        [button-text]="hint.buttonText" [button-text-skip]="hint.buttonTextSkip" (clicked-ok)="clickedHint(index)"
        [arrow]="hint.arrow" (clicked-skip)="clickedHintSkip(index)">
    </app-hint>
</ng-container>