<router-outlet *ngIf="ready"></router-outlet>

<app-contact-form></app-contact-form>
<!-- loading window FARM LAYOUTS start -->

<div class="farm-loader full-frame" *ngIf="translationsLoaded && (farmLoading || !farmAcknowledged)">
  <!-- loading -->
  <ng-container *ngIf="farmLoading">
    <div class="element loading full-frame">
      <img class="logo" src="./assets/ui/logos/gea-logo-payoff-white.svg" />
      <h1 translate>main.app-title</h1>
      <!-- </div>
    <div class="element"> -->
      <!-- <br /> -->
      <div class="loading-bar">
        <!-- <img
          class="loading-icon"
          src="./assets/ui/icons/loading-icon-blue.svg"
        /> -->
        <!-- <span class="message" translate>loading.progress-bar-text</span> -->

        <div class="fill" [ngStyle]="{ width: progress | percent }">
          <!-- <img
            class="loading-icon"
            src="./assets/ui/icons/loading-icon-white.svg"
          /> -->
        </div>
      </div>
      <span class="message" translate>loading.progress-bar-text</span>
    </div>
    <!-- <div class="element"></div> -->
  </ng-container>
  <!-- loading end -->

  <!-- done loading - start button  -->
  <ng-container *ngIf="!farmAcknowledged && !farmLoading">
    <app-sidebar can-close="true" #sidebar>
      <h2 translate>main.app-title</h2>
      <!-- <h3 translate>main.sub-title</h3> -->
      <p class="version small" [translate]="getVersionTranslation()"></p>
      <div class="flow">
        <a class="rounded green large" (click)="clickedAcknowledged()">
          <!-- <img src="./assets/ui/icons/icon-rocket-blue.svg"> -->
          <span translate>buttons.intro.start-app</span>
        </a>
      </div>
    </app-sidebar>
  </ng-container>
  <!-- done loading - start button end  -->
</div>
<!-- loading window FARM LAYOUTS end -->

<!-- loading window PANO start -->
<div class="pano-loader full-frame" *ngIf="panoLoading || (panoLoaded && !ready)">
  <div class="bg-overlay-color"></div>

  <div class="full-frame content">
    <h1 innerHTML="{{ 'loading.pano.title' | translate }}"></h1>

    <div class="inner-content">
      <p [innerHTML]="'loading.pano.contact-denied-message' | translate"></p>
    </div>

    <!-- START: loading visual -->

    <!-- using appFadeVisible to keep it in the HTML but hidden (for layouting purposes) -->
    <!-- the ngIf is for the continue-button below - both the loading bar and continue button should be in the same location
             so when the continue-buttons should be visible, the ngIf in the loading-bar will remove the loading-bar from the html -->
    <div class="loading-bar" [appFadeVisible]="panoLoading" *ngIf="panoLoading">
      <div class="fill" [ngStyle]="{ width: progress | percent }"></div>
    </div>

    <!-- END: loading visual -->
  </div>
</div>
<!-- loading window PANO end -->

<!-- containers for preload-data -->
<div class="hidden loaddata" #farmloaddata></div>
<div class="hidden loaddata" #panoloaddata></div>

<app-cookie-consent *ngIf="needsConsent" (onUserMadeAChoice)="onCookieSelectionMade()"></app-cookie-consent>
