<div class="navbar hidden" [appFadeVisible]="!barOpen">


    <a class="rounded center large tertiarybutton" *ngIf="backTranslate != ''"
    (click)="clickedBack()">
        <img src="./assets/ui/icons/icon-arrow-left-white.svg">
        <span>
            {{backTranslate | translate}}
        </span>
    </a>
    <a class="rounded  large green"
    (click)="clickedForward()"
    [ngClass]="{'idle-wobble': isPhone}"
        *ngIf="forwardTranslate != ''">
        <span>
            {{forwardTranslate | translate }}
        </span>
        <img src="./assets/ui/icons/icon-arrow-right-black.svg">
    </a>
</div>