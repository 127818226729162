import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, AfterViewInit {

  @ViewChild("video") video: ElementRef;

  getVideoElement(): HTMLVideoElement {
    if (this.video != undefined && this.video.nativeElement != undefined)
      return <HTMLVideoElement>(this.video.nativeElement);

    return undefined;
  }

  @Input() videopath: string = "";
  @Input() mode: 'cover' | 'contain' = "cover";
  @Output() finished = new EventEmitter();
  @Input() autoplay: boolean = false;
  @Output() failedAutoplay = new EventEmitter();
  @Input() loop: boolean = false;
  @Input() muted: boolean = true;
  @Input() controls : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    //typecheck the bool - it might actually just exist and contain a false value
    //that would trigger a true without the typecheck :P stupid javascript is stupid
    if (this.autoplay === true) {
      this.play();
    }

    if (this.loop)
      this.getVideoElement().loop = true;
  }

  play() {
    this.getVideoElement().load();
    let playPromise = this.getVideoElement().play();

    if (playPromise != undefined) {
      playPromise.then(() => { }).catch((error) => {
        this.failedAutoplay.emit();
      })
    }
  }

  videoDonePlaying() {
    this.finished.emit();
  }

}
