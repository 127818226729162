import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Farm, FarmTour } from '../models/farm';
import { Filter } from '../models/filter';
import { Tour } from '../shared/panoSystem/models/tour';
import * as THREE from 'three';
import { getRootCSSVariableValue } from '../_submodules/letink.shared.angular.core/functions/get-css-root-variables';

export const FILTERS: Filter[] = [
  {
    id: 'feeding',
    color: 'feeding',
    icon: 'feeding',
    title: 'filters.feeding.title',
    desc: 'filters.feeding.desc',
  },
  {
    id: 'milking',
    color: 'milking',
    icon: 'milking',
    title: 'filters.milking.title',
    desc: 'filters.milking.desc',
  },
  {
    id: 'servicehygiene',
    color: 'servicehygiene',
    icon: 'servicehygiene',
    title: 'filters.servicehygiene.title',
    desc: 'filters.servicehygiene.desc',
  },
  // {
  //   id: 'manure',
  //   color: 'manure',
  //   icon: 'manure',
  //   title: 'filters.manure.title',
  //   desc: 'filters.manure.desc',
  // },
];

const FARMS: string[] = [
  './assets/data/FarmMeta/60/farm-data.json',
  './assets/data/FarmMeta/120-A/farm-data.json',
  './assets/data/FarmMeta/120-B/farm-data.json',
  './assets/data/FarmMeta/240-A/farm-data.json',
  './assets/data/FarmMeta/240-B/farm-data.json',
  './assets/data/FarmMeta/480/farm-data.json',
  // "./assets/data/FarmMeta/850/farm-data.json",
];

export const tourBase: FarmTour = {
  basicConfig: {
    fontData: {
      fontSize: 0.2,
      fontSizeCenter: 0.2,
      fontColor: new THREE.Color(0xffffff),
      fontColorCenter: new THREE.Color(0xffffff),
      outlineColor: new THREE.Color(0x000000),
      outlineWidth: 0.01,
      outlineBlur: '10%',
      outlineOpacity: 0.2,
    },
    clickableMaterials: [
      {
        id: 'main',
        width: 0.4,
        height: 0.4,
        image: './assets/ui/icons/panoswitch-bg.png',
      },
      {
        id: 'infotile',
        width: 0.4,
        height: 0.4,
        image: './assets/ui/icons/panoswitch-bg-infotile.svg',
      },
      {
        id: 'feeding',
        width: 0.4,
        height: 0.4,
        image: './assets/ui/icons/panoswitch-bg-feeding.svg',
      },
      {
        id: 'milking',
        width: 0.4,
        height: 0.4,
        image: './assets/ui/icons/panoswitch-bg-milking.svg',
      },
      {
        id: 'servicehygiene',
        width: 0.4,
        height: 0.4,
        image: './assets/ui/icons/panoswitch-bg-servicehygiene.svg',
      },
      {
        id: 'all',
        width: 0.4,
        height: 0.4,
        image: './assets/ui/icons/panoswitch-bg-all.svg',
      },
      {
        id: 'fulltour',
        width: 0.4,
        height: 0.4,
        image: './assets/ui/icons/panoswitch-bg-all.svg',
      },
    ],
  },
  startupscene: '',
  shortcuts: [],
  scenes: [],
  infotiles: [],
};

const SESSION_KEY_CATEGORY:string  = "farm-category";
const SESSION_KEY_PANO:string  = "pano-number";

@Injectable({
  providedIn: 'root',
})
export class FarmService {
  public farms: BehaviorSubject<Farm[]> = new BehaviorSubject(undefined);

  private _farms: Farm[];

  public shouldLoadLQFiles : boolean = false;
  public isPhone : boolean = false;
  public isTablet : boolean = false;


  constructor(private http: HttpClient) {
    const device = getRootCSSVariableValue("--device-group");
    this.shouldLoadLQFiles = device == "phone" || device == "tablet";
    this.isPhone = device == "phone";
    this.isTablet = device == "tablet";
  }

  public setPanoID(id : string) {
    sessionStorage.setItem(SESSION_KEY_PANO,id.toString());
  }

  public setTourCategory(category : string){
    sessionStorage.setItem(SESSION_KEY_CATEGORY,category);
  }

  public getTourCategory() : string{
    let cat = sessionStorage.getItem(SESSION_KEY_CATEGORY);
    if(cat == undefined || cat == '')
      cat = "fulltour";
    return cat;
  }

  public getPano(): string {
    let pano = sessionStorage.getItem(SESSION_KEY_PANO);
    if(pano == undefined || pano == '')
      return "intro"

    return pano;
  }

  public init() {
    //download farms
    this._farms = [];
    this.farms.next(undefined);
    FARMS.forEach((farm) => {
      this.http.get<Farm>(farm).subscribe((farmResult) => {
        //fetch pano route for this farm
        const url =
          './assets/data/FarmMeta/' + farmResult.id + '/pano-route.json';

        this.http.get<FarmTour>(url).subscribe((panoResult) => {
          let tour = Object.assign(new FarmTour(), tourBase);
          tour = Object.assign(tour, panoResult);

          farmResult.farmTour = tour;

          this._farms.push(farmResult);

          if (this._farms.length == FARMS.length) {
            this._farms.sort((a, b) => {
              if (a.herdSize > b.herdSize) return 1;
              else if (a.herdSize < b.herdSize) return -1;
              else return a.layout > b.layout ? 1 : -1;
            });
            this.farms.next(this._farms);
          }
        });
      });
    });
  }

  public getFarms(): Farm[] {
    return this._farms;
  }
}
