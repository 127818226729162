<app-tour #tour class="full-frame"
 (clickableClicked)="clickedClickable($event)"
 (moved)="onPanoMoved()"
 ></app-tour>

<img class="gea-logo" src="./assets/ui/logos/gea-logo-payoff-white.svg" *ngIf="!isPhone && !isEndPopupOpen && !infotileOpen">
<img class="gea-logo" src="./assets/ui/logos/gea-logo-no-payoff.svg" *ngIf="isPhone && !isEndPopupOpen && !infotileOpen">

<!-- marcus start -->
<ng-container *ngIf="marcusElement != undefined && marcusElement.valid">
  <div class="marcus-container" [ngClass]="{'visible' : !marcusElement.closed}">
    <img class="marcus" [ngClass]="{'marcusClose': marcusElement.closed, 'marcusOpen': !marcusElement.closed}"  [appFadeVisible]="isPlaying()" src="./assets/data/marcus/talking.webp" />
    <img class="marcus" [ngClass]="{'marcusClose': marcusElement.closed, 'marcusOpen': !marcusElement.closed}" [appFadeVisible]="!isPlaying()" src="./assets/data/marcus/idle.webp" />
    
    <div class="popup" [ngClass]="{'popupClose': marcusElement.closed, 'popupOpen': !marcusElement.closed}">
      <div class="popup-content">

        <div class="close-and-audio-element">

          <div class="menu-button close" (click)="this.closeMarcus()">
            <img src="./assets/ui/icons/icon-x.svg" />
          </div>
          <audio #audio autoplay controls class="audio" (ended)="audioEnded()" [ngClass]="{'hidden': marcusElement?.elements[marcusElement.elementIndex]?.file == ''}">
            Your browser does not support the audio element.
          </audio>

        </div>

        <div class="scroll-area">

          <div class="text" [innerHTML]="marcusElement?.elements[marcusElement.elementIndex]?.text"></div>
          <div class="buttons" *ngIf="marcusElement.elements.length > 1">
            <a class="rounded large tertiarybutton" (click)="previousMarcus()" *ngIf="marcusElement.elementIndex > 0">
                <img src="./assets/ui/icons/icon-arrow-left-white.svg">
                <span>
                    {{'buttons.shared.previous' | translate}}
                </span>
            </a>
            <a class="button rounded green" (click)="nextMarcus()" *ngIf="marcusElement.elementIndex < marcusElement.elements.length - 1 && marcusElement.elements.length > 1">
              <span translate>buttons.shared.next</span>
            </a>
          </div>

        </div>
      </div>
    </div>

  </div>
</ng-container>
<!-- marcus end -->


<!-- navigation start -->
<app-navigatorbar 
[bar-open]="isEndPopupOpen || infotileOpen"

backTranslate="main.navigation.backwards.routes"
forwardTranslate=""
(backClicked)="backClickedNavigator()"
(forwardClicked)="forwardClickedNavigator()">

></app-navigatorbar>
<!-- navigation end -->


<!-- last pano popup -->
<div class="tour-end full-frame hidden" [appFadeVisible]="isEndPopupOpen">
  <div class="popup">

    <img class="finish" src="./assets/ui/icons/icon-finish.svg">

    <span class="message" translate>app-end.desc</span>

    <div class="button-container">

      <a class="rounded tertiarybutton" (click)="onClickTourend_Restart()">
        <img src="./assets/ui/icons/icon-arrow-left-white.svg">
        <span translate>app-end.button-again</span>
      </a>

      <a class="rounded large green" (click)="onClickTourend_Another()">
        <span translate>app-end.button-another-tour</span>
      </a>

    </div>
    
  <div class="menu-button close" (click)="this.onClickTourend_Continue()">
    <img src="./assets/ui/icons/icon-x.svg" />
  </div>

  </div>
</div>
<!-- last pano popup end -->

<!-- infotile start -->
<app-infotile class="hidden" 
[appFadeVisible]="infotileOpen" [data]="currentInfotile" [open]="infotileOpen"
  (onClose)="clickedCloseInfotile()"></app-infotile>
<!-- infotile end -->

<!-- hints start -->
<ng-container *ngFor="let hint of hints; let index = index">
  <app-hint class="hidden" [appFadeVisible]="index == hintIndex" [image]="hint.image" [text]="hint.text"
    [button-text]="hint.buttonText" [button-text-skip]="hint.buttonTextSkip" (clicked-ok)="clickedHint(index)"
    [arrow]="hint.arrow" (clicked-skip)="clickedHintSkip(index)">
  </app-hint>
</ng-container>
<!-- hints end -->