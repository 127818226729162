import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Farm, PanoVoiceoverMapping } from 'src/app/models/farm';
import { PanoInfoTile } from 'src/app/models/pano-infotile';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { FarmService, tourBase } from 'src/app/services/farm.service';
import { Clickable } from 'src/app/shared/panoSystem/models/tour';
import { TourComponent } from 'src/app/shared/panoSystem/tour.component';
import { SidebarComponent } from 'src/app/shared/sidebar/sidebar.component';
import { HintElement } from '../farm-selector/farm-selector.component';
import * as THREE from 'three';
import { FontData } from 'src/app/shared/panoSystem/models/pano-viewer-config';
import { ContactService } from 'src/app/contact-form/contact.service';
import { RoutingService } from 'src/app/services/routing.service';
import { environment } from 'src/environments/environment';

export interface MarcusDetails {
  valid: boolean;
  closed: boolean;

  elements: VoiceoverElement[];
  //the current state
  elementIndex: number;
}

export interface VoiceoverElement {
  text: string;
  file: string;
}

@Component({
  selector: 'app-pano-route',
  templateUrl: './pano-route.component.html',
  styleUrls: ['./pano-route.component.scss'],
})
export class PanoRouteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(SidebarComponent, { static: false }) sidebar: SidebarComponent;
  @ViewChild(TourComponent, { static: false }) tour: TourComponent;

  @ViewChild('audio') audio: ElementRef;

  isPhone : boolean = false;

  ngOnDestroy() {
    this.stopTourEndPopupTimer();
  }

  getAudioElement(): HTMLAudioElement {
    if (this.audio != undefined && this.audio.nativeElement != undefined) return <HTMLAudioElement>this.audio.nativeElement;

    return undefined;
  }

  isPlaying(): boolean {
    const audio = this.getAudioElement();
    if (audio == undefined) return false;

    return !audio.ended && !audio.paused && audio.readyState > 2;
  }

  sidebarOpen: boolean = true; //cached value

  currentInfotile?: PanoInfoTile;
  infotileOpen: boolean = false;
  contactFormOpen: boolean = false;
  isEndPopupOpen: boolean = false;

  marcusElement: MarcusDetails = {
    closed: false,
    valid: false,
    elementIndex: -1,
    elements: [],
  };

  private readonly hints_shown_sessionkey = 'PANOTOUR_HINTS';
  hints: HintElement[] = [
    {
      image: './assets/ui/icons/hint-click.svg',
      text: 'hints.pano',
      buttonText: 'buttons.shared.understood',
      buttonTextSkip: '',
      arrow: '',
    },
  ];
  hintIndex: number = 0;

  constructor(
    private farmService: FarmService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private analytics: AnalyticsService,
    private contact : ContactService,
    private rs : RoutingService
  ) {}

  startupSceneId: string;
  farm: Farm;

  sceneChangeSub: Subscription;

  layout: string;
  ngOnInit(): void {
    this.isPhone = this.farmService.isPhone;
    this.hintIndex = this.hints.length;
    this.startupSceneId = this.farmService.getPano();

    this.route.paramMap.subscribe((map) => {
      if (map.has('layout')) {
        const layout = map.get('layout');
        this.layout = layout;
        const farms = this.farmService.getFarms();
        this.farm = farms.find((x) => x.id == layout);
      }
    });
  }

  ngAfterViewInit() {
    if (this.farm != undefined && this.tour != undefined) {
      const filter = this.farmService.getTourCategory();
      this.tour.setFilter(filter, filter);
      this.translate.get('buttons.shared.next').subscribe((result) => {
        this.prepareTourForRun(result);

        this.sceneChangeSub = this.tour.sceneChanged.subscribe((val) => {
          this.onSceneChange(val);
        });

        this.tour.initTour(this.farm.farmTour);

        this.changeDetectorRef.detectChanges();
      });
    }
  }

  startHints() {
    const sessionVal = sessionStorage.getItem(this.hints_shown_sessionkey);
    if (sessionVal != null && sessionVal == this.hints_shown_sessionkey) this.hintIndex = this.hints.length;
    else this.hintIndex = 0;
    
    this.contact.HintsOpen.next(this.hintIndex < this.hints.length);
  }

  onSceneChange(sceneID: string) {
    this.thisIsLastPanoInTour = false;
    this.contact.LastPanoPopupOpen.next(false);
    this.initMarcus(sceneID);
    this.farmService.setPanoID(sceneID);

    //find last pano of this tour
    const category = this.farmService.getTourCategory();
    let lastSceneOfTour = 'kitchen';

    if (category != 'fulltour') {
      for (let i = this.farm.route_infopoints.length - 1; i >= 0; i--) {
        if (this.farm.route_infopoints[i].theme_ids.indexOf(category) > -1) {
          lastSceneOfTour = this.farm.route_infopoints[i].panoid;
          break;
        }
      }
    }

    if (lastSceneOfTour == sceneID) {
      //last scene
      this.isLastPano();
    }

    // this.devOpenInfotile();
  }

  thisIsLastPanoInTour: boolean;
  timeout: number | undefined = undefined;

  isLastPano() {
    this.thisIsLastPanoInTour = true;
  }

  stopTourEndPopupTimer() {
    if (this.timeout != undefined) window.clearTimeout(this.timeout);
  }
  onPanoMoved() {
    this.startTourEndPopupTimer();
  }
  startTourEndPopupTimer() {
    if (!this.thisIsLastPanoInTour) return;

    this.stopTourEndPopupTimer();

    this.timeout = window.setTimeout(() => {
      this.isEndPopupOpen = true;
      this.contact.LastPanoPopupOpen.next(true);
      this.thisIsLastPanoInTour = false;
      this.stopTourEndPopupTimer();
    }, 5 * 1000);
  }

  initMarcus(scene: string) {
    //fetch key for voiceovers from farm setup
    let key: PanoVoiceoverMapping | undefined = undefined;

    if (this.farm != undefined && this.farm.voiceover_mapping != undefined) key = this.farm.voiceover_mapping.find((x) => x.sceneid == scene);

    if (key == undefined) {
      this.marcusElement = {
        closed: true,
        valid: false,
        elementIndex: -1,
        elements: [],
      };

      this.startTourEndPopupTimer();
    } else {
      const lang = this.translate.currentLang;
      const folder = './assets/data/voiceovers/' + lang + '/';

      let categories = [this.farmService.getTourCategory()];
      let elements: VoiceoverElement[] = [];

      var found: boolean = false;
      key.voiceoverids.forEach((vo) => {
        const textKey = `voiceovers.fulltour.${vo}.text`;
        if (this.translate.instant(textKey) !== textKey) found = true;
      });

      if (!found) categories = ['fulltour', 'feeding', 'milking', 'servicehygiene', 'manure'];

      categories.forEach((category) => {
        key.voiceoverids.forEach((voiceoverid) => {
          const fileKey = 'voiceovers.' + category + '.' + voiceoverid + '.file';
          const textKey = 'voiceovers.' + category + '.' + voiceoverid + '.text';
          const text = this.translate.instant(textKey);
          const file = this.translate.instant(fileKey);

          if (text != textKey && text != '') {
            //got a translation, we didn't get the key back
            elements.push({
              text: text,
              file: file != fileKey && file != '' ? folder + file + '.mp3' : '',
            });
          }
        });
      });

      if (elements.length > 0) {
        this.marcusElement = {
          closed: false,
          valid: true,
          elementIndex: 0,
          elements: elements,
        };

        setTimeout(() => {
          const aud = this.getAudioElement();
          const file = this.marcusElement.elements[this.marcusElement.elementIndex].file;
          if (aud != undefined) {
            aud.src = this.marcusElement.elements[this.marcusElement.elementIndex].file;

            if (file != '') {
              aud.load();
              aud.play();
            }
          }
        }, 100);
      } else {
        this.marcusElement = {
          closed: true,
          valid: false,
          elementIndex: -1,
          elements: [],
        };
        this.startTourEndPopupTimer();
        this.startHints();
      }
    }
  }

  audioEnded() {
    this.nextMarcus();
  }
  previousMarcus() {
    this.marcusElement.elementIndex--;
    if (this.marcusElement.elementIndex >= 0) {
      setTimeout(() => {
        const aud = this.getAudioElement();
        if (aud != undefined) {
          aud.src = this.marcusElement.elements[this.marcusElement.elementIndex].file;
          aud.load();
          aud.play();
        }
      }, 100);
    } else {
      //done
      //should we do something?
      this.marcusElement.closed = true;
      this.marcusElement.elementIndex = -1;
      this.getAudioElement().pause();
      this.startHints();
    }
  }

  nextMarcus() {
    this.marcusElement.elementIndex++;
    if (this.marcusElement.elementIndex <= this.marcusElement.elements.length - 1) {
      setTimeout(() => {
        const aud = this.getAudioElement();
        if (aud != undefined) {
          aud.src = this.marcusElement.elements[this.marcusElement.elementIndex].file;
          aud.load();
          aud.play();
        }
      }, 100);
    } else {
      //done
      //should we do something?
      this.marcusElement.closed = true;
      this.marcusElement.elementIndex = -1;
      this.getAudioElement().pause();
      this.startTourEndPopupTimer();
      this.startHints();
    }
  }

  closeMarcus() {
    this.getAudioElement().pause();
    this.marcusElement.closed = true;
    this.startTourEndPopupTimer();
    this.startHints();
  }

  prepareTourForRun(translatedNextString: string) {
    this.farm.farmTour.startupscene = this.startupSceneId;

    this.farm.farmTour.scenes.forEach((scene) => {
      if (this.farmService.shouldLoadLQFiles) {
        if(scene.filename.indexOf("_mobile") == -1)
          scene.filename = scene.filename.replace('.jpg', '_mobile.jpg');
      }
      scene.switches.forEach((sw) => {
        sw.label_center = translatedNextString;
        sw.materialID = sw.materialID;

        let fontOverride = Object.assign(new FontData(), tourBase.basicConfig.fontData);

        sw.overrideFontdata = Object.assign(fontOverride, {
          outlineOpacity: 0.2,
          centerY: 0.045,
          fontSizeCenter: 0.06,
        });

        if (sw.materialID == 'servicehygiene') {
          sw.overrideFontdata.fontColorCenter = new THREE.Color(0x000000);
          sw.overrideFontdata.outlineWidth = 0;
        }
        let storedLang = localStorage.getItem(environment.sessionkey_language);
        if(storedLang == "zh")
        {
          sw.overrideFontdata.fontTTF = "./assets/fonts/SimSun.ttf"
          sw.overrideFontdata.fontSizeCenter = 0.075;
        }
      });

      const infotiles = this.farm.farmTour.infotiles.filter((x) => x.panoid == scene.id);
      if (infotiles != undefined) {
        scene.other_clickables = [];
        infotiles.forEach((info) => {
          const tileHeader = this.translate.instant('products.' + info.translationid + '.title');

          let fontOverride = Object.assign(new FontData(), tourBase.basicConfig.fontData);
          let infoClickable: Clickable = {
            materialID: info.materialID != undefined ? info.materialID : 'infotile',
            id: info.id,
            x: info.x,
            y: info.y,
            label_top: tileHeader,
            label_center: 'i',
            scale: 1,
            overrideFontdata: fontOverride,
          };
          if (info.materialID == 'servicehygiene') {
            infoClickable.overrideFontdata.fontColorCenter = new THREE.Color(0x000000);
            infoClickable.overrideFontdata.outlineWidth = 0;
          }

          let storedLang = localStorage.getItem(environment.sessionkey_language);
          if(storedLang == "zh")
          {
            infoClickable.overrideFontdata.fontTTF = "./assets/fonts/SimSun.ttf"
          }

          scene.other_clickables.push(infoClickable);
        });
      }
    });
  }

  onSidebarStateChange(val: boolean) {
    this.sidebarOpen = val;
  }

  clickedHint(index: number) {
    this.hintIndex++;

    sessionStorage.setItem(this.hints_shown_sessionkey, this.hints_shown_sessionkey);
    this.contact.HintsOpen.next(this.hintIndex < this.hints.length);

    this.changeDetectorRef.detectChanges();
  }

  clickedHintSkip(index: number) {
    this.hintIndex = this.hints.length;
    sessionStorage.setItem(this.hints_shown_sessionkey, this.hints_shown_sessionkey);
    this.contact.HintsOpen.next(this.hintIndex < this.hints.length);

    this.changeDetectorRef.detectChanges();
  }

  clickedClickable(clickable: Clickable) {
    const infotile = this.farm.farmTour.infotiles.find((x) => x.id == clickable.id);
    if (infotile != undefined) {
      this.currentInfotile = infotile;
      this.infotileOpen = true;
      this.contact.InfotileOpen.next(true);
      this.tour.panoviewer.hideClickables();
      this.stopTourEndPopupTimer();
    }
  }

  devOpenInfotile() {
    const tile = this.farm.farmTour.infotiles[0];
    this.currentInfotile = tile;
    this.infotileOpen = true;
    this.tour.panoviewer.hideClickables();
    this.stopTourEndPopupTimer();
  }

  clickedCloseInfotile() {
    this.currentInfotile = undefined;
    this.infotileOpen = false;
    this.tour.panoviewer.showClickables();
    this.startTourEndPopupTimer();
    this.contact.InfotileOpen.next(false);
  }
  onClickTourend_EndTour() {
    this.isEndPopupOpen = false;
    this.contact.LastPanoPopupOpen.next(false);
    this.contactFormOpen = true;
  }

  onClickTourend_Another() {
    const snapshot = this.route.snapshot;
    const url = snapshot.url;
    const farmLayoutUrlPart = url[0].path;
    this.router.navigate(['/', farmLayoutUrlPart]);
  }

  onClickTourend_Continue() {
    this.isEndPopupOpen = false;
  }

  onClickTourend_Restart() {
    //find first point of this theme
    const category = this.farmService.getTourCategory();
    let sceneid = 'intro';

    if (category != 'fulltour') {
      const point = this.farm.route_infopoints.find((x) => x.theme_ids.indexOf(category) > -1);
      if (point != undefined) {
        sceneid = point.panoid;
      }
    }

    this.isEndPopupOpen = false;
    this.tour.gotoScene(sceneid);
  }

  
  backClickedNavigator(){
    this.rs.navigate(['/', this.layout], false);
  }

  forwardClickedNavigator(){
  }
}
