import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent implements OnInit {

  @Input("image") image: string;
  @Input("text") text: string;
  @Input("button-text") buttonText: string;
  @Input("button-text-skip") buttonTextSkip: string;

  @Input("arrow") arrow : string = "";

  @Output("clicked-ok") clicked = new EventEmitter();
  @Output("clicked-skip") skipped = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  clickedButton() {
    this.clicked.emit();
  }
  clickedSkip() {
    this.skipped.emit();
  }

}
