<div class="hint-body">
  <img class="hint-image" [src]="image" />
  <span class="hint-text" [translate]="text"></span>
  <div class="button-row">
    <a class="rounded large green" (click)="clickedButton()">
      <span [translate]="buttonText"></span>
    </a>
    <a *ngIf="buttonTextSkip != ''" class="" (click)="clickedSkip()">
      <span [translate]="buttonTextSkip"></span>
      <img src="./assets/ui/icons/icon-arrow-right-white.svg" />
    </a>
  </div>
  <img
    *ngIf="arrow == 'right'"
    src="./assets/ui/icons/hint-arrow-right.svg"
    class="arrow arrow-right"
  />
  <img
    *ngIf="arrow == 'down'"
    src="./assets/ui/icons/hint-arrow-down.svg"
    class="arrow arrow-down"
  />
</div>
