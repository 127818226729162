import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Farm } from 'src/app/models/farm'
import { RouteSelectorInfopoint } from 'src/app/models/route-selector-infopoint'
import { AnalyticsService } from 'src/app/services/analytics.service'
import { FarmService, FILTERS } from 'src/app/services/farm.service'
import { PannerComponent } from 'src/app/shared/panner/panner.component'
import {
  SidebarComponent,
  SIDEBAR_WIDTH_PX,
} from 'src/app/shared/sidebar/sidebar.component'
import { HintElement } from '../farm-selector/farm-selector.component'
import { ContactService } from 'src/app/contact-form/contact.service'
import { RoutingService } from 'src/app/services/routing.service'

@Component({
  selector: 'app-route-selection',
  templateUrl: './route-selection.component.html',
  styleUrls: ['./route-selection.component.scss'],
})
export class RouteSelectionComponent implements OnInit, AfterViewInit {
  SIDEBAR_WIDTH_PX = SIDEBAR_WIDTH_PX

  @ViewChild('content', { static: false }) content: ElementRef
  @ViewChild('panner', { static: false }) panner: PannerComponent
  @ViewChild(SidebarComponent, { static: false }) sidebar: SidebarComponent
  sidebarOpen: boolean = true //cached value

  FILTERS = FILTERS

  layout: string = ''

  farm: Farm
  image_container_style: object

  backwardUrl: string[] = []
  forwardUrl: string[] = []

  currentFilter: string = 'fulltour'
  openPopup: number = -1

  isPhone : boolean = false;

  forwardTranslateID = ''
  sidebarStartOpen:boolean = true;

  constructor(
    private route: ActivatedRoute,
    private farmService: FarmService,
    private router: Router,
    private analytics: AnalyticsService,
    private translate: TranslateService,
    private changeRef : ChangeDetectorRef,
    private contact : ContactService,
    private rs : RoutingService
  ) {}

  ngOnInit(): void {
    this.sidebarStartOpen = this.rs.sidebarOpenOnInit;
    this.isPhone = this.farmService.isPhone;
    this.route.paramMap.subscribe((map) => {
      if (map.has('layout')) {
        this.layout = map.get('layout')
        const farms = this.farmService.getFarms()
        this.farm = farms.find((x) => x.id == this.layout)

        if (this.farm != undefined) {

          this.updateImage()

          this.backwardUrl = ['/']
          this.forwardUrl = ['/', this.layout, 'pano']

          let filter = this.farmService.getTourCategory();
          if(this.farm.herdSize == 480 && filter == 'feeding'){
            this.farmService.setTourCategory('fulltour');
          }
          
          filter = this.farmService.getTourCategory();
          this.setFilter(filter)
          this.togglePopup(-1)
        }
      }
    })
  }

  ngAfterViewInit(): void {
    if (this.panner) {
      this.panner.reset()
      if(this.isPhone){
        this.panner.move_to_percentage(0.33, 0.5);
      }
    }
    else console.warn("afterviewinit but panner is still unreachable!");
  }


  shouldShowFilter(filter : string){
    if(filter == 'feeding' && this.farm.herdSize == 480) return false;

    return true;
  }

  updateImage() {
    //background image
    this.image_container_style = {
      'background-image': "url('" + this.farm.render_floorplan + "')",
    }
    if (this.panner) this.panner.reset()
    else console.warn("can't reset panner - panner not found")
  }

  onSidebarStateChange(val: boolean) {
    this.sidebarOpen = val;
    this.changeRef.detectChanges();
  }

  startRouteAtPoint(id: string) {
    this.farmService.setPanoID(id)
    this.router.navigate(this.forwardUrl)
  }

  clickedStartTour(){
    this.analytics.send('tour_started', this.currentFilter);
  }

  isFilterOpen(filter : string) : boolean{
    return (filter == this.currentFilter)
  }

  setFilter(filter: string) {
    if (this.currentFilter == filter) this.currentFilter = 'fulltour'
    else this.currentFilter = filter

    this.farmService.setTourCategory(this.currentFilter);
    if (this.currentFilter != 'fulltour') {
      this.forwardTranslateID = 'filters.' + this.currentFilter + '.to-button'

      // find first point with this filter
      const point = this.farm.route_infopoints.find((x) => x.theme_ids.indexOf(this.currentFilter) >-1)
      if (point != undefined) {
        this.farmService.setPanoID(point.panoid)
      } else {
        this.farmService.setPanoID("intro")
      }
    } else {
      this.forwardTranslateID = 'filters.all.to-button'
      this.farmService.setPanoID("intro")
    }
  }

  shouldPointBeVisible(point : RouteSelectorInfopoint){
    // if(this.currentFilter == 'fulltour')
    //   return true;

    return point.theme_ids.indexOf(this.currentFilter) > -1;
  }

  getPointClass(point : RouteSelectorInfopoint){

    return this.currentFilter;
    // if(point.theme_ids.indexOf(this.currentFilter) > -1)
    //   return this.currentFilter;

    // return point.theme_ids[0];
  }

  togglePopup(number: number) {
    if (this.openPopup == number) this.openPopup = -1
    else {
      const title = this.translate.instant(
        'route-selection.' + this.layout + '.' + number.toString() + '.title',
      )

      this.openPopup = number
    }
  }

  clickedBackInSidebar(){
    // [routerLink]="backwardUrl"
    this.rs.navigate(this.backwardUrl, false);
  }
  
  backClickedNavigator(){
    if(this.isPhone)
      this.sidebar.open();
    else
      this.rs.navigate(this.backwardUrl, false);
  }

  forwardClickedNavigator(){
    this.rs.navigate(this.forwardUrl, false);
  }
}
