import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

export class DropdownData {
  public icon: string;
  public label: string;
  public internalvalue: any;
}

@Component({
  selector: 'app-ui-dropdown',
  templateUrl: './ui-dropdown.component.html',
  styleUrls: ['./ui-dropdown.component.scss']
})
export class UiDropdownComponent implements OnInit {

  @Input("dropdown-data") dropdownData: DropdownData[];

  @Input("value") value: DropdownData;
  @Output("valueChange") valueChange = new EventEmitter<DropdownData>();

  open: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  clickedDropdown() {
    this.open = true;
  }

  clickedBlocker() {
    this.open = false;
  }

  clickedValue(val: DropdownData) {
    this.value = val;
    this.open = false;
    this.valueChange.emit(val);
  }

}
