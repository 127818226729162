import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FarmSelectorComponent } from 'src/app/pages/farm-selector/farm-selector.component';
import { PanoRouteComponent } from 'src/app/pages/pano-route/pano-route.component';
import { RouteSelectionComponent } from 'src/app/pages/route-selection/route-selection.component';
import { FarmService } from 'src/app/services/farm.service';

export enum AppPart {
  Farm,
  Route,
  Pano
}

@Component({
  selector: 'app-navigatorbar',
  templateUrl: './navigatorbar.component.html',
  styleUrls: ['./navigatorbar.component.scss']
})
export class NavigatorbarComponent implements OnInit, OnDestroy, OnChanges {

  @Input("bar-open") barOpen: boolean;

  @Output() backClicked  = new EventEmitter();
  @Output() forwardClicked  = new EventEmitter();

  @Input() backTranslate : string = "";
  @Input() forwardTranslate : string = "";

  part: AppPart = AppPart.Farm;
  AppPart = AppPart;

  isPhone : boolean = false;

  constructor(private route: ActivatedRoute, private farmService: FarmService) { }

  clickedBack(){
    this.backClicked.emit()
  }
  clickedForward(){
    this.forwardClicked.emit()
  }

  ngOnInit(): void {

    this.isPhone = this.farmService.isPhone;

    // const snapshot = this.route.snapshot;
    // const url = snapshot.url;

    // switch (url.length) {
    //   case 0:
    //     //root - so farm
    //     this.part = AppPart.Farm;
    //     break;
    //   case 1:
    //     //route part - save route id
    //     this.farmLayoutUrlPart = url[0].path;
    //     this.part = AppPart.Route;
    //     break;
    //   case 2:
    //     this.farmLayoutUrlPart = url[0].path;
    //     this.part = AppPart.Pano;
    //     break;
    // }
    // this.buildUrls();
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.buildUrls();

    // if (changes.farmUrlOverride != undefined) {
    // }
  }

  buildUrls() {

    // if(this.farmUrlOverride != '')
    //   this.farmLayoutUrlPart = this.farmUrlOverride;

    // this.previousLink = ['/'];
    // this.nextLink = ['/'];

    // switch (this.part) {
    //     case AppPart.Farm:
    //     this.nextLink.push(this.farmLayoutUrlPart);
    //     this.forwardTranslation = "main.navigation.forward.floorplan";
    // break;
    //   case AppPart.Route:
    //     this.nextLink.push(this.farmLayoutUrlPart);
    //     this.nextLink.push('pano');

    //     this.backTranslation = "main.navigation.backwards.overview";
    //     // this.forwardTranslation = "main.navigation.interior";
    // break;
    //   case AppPart.Pano: 
    //     this.previousLink.push(this.farmLayoutUrlPart);
    //     this.backTranslation = "main.navigation.backwards.floorplan";
    //     this.forwardTranslation = "app-end.button-end";
    // break;
    // }
  }
}
