import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FarmSelectorComponent } from './pages/farm-selector/farm-selector.component';
import { PanoRouteComponent } from './pages/pano-route/pano-route.component';
import { RouteSelectionComponent } from './pages/route-selection/route-selection.component';

const routes: Routes = [
  //pano is a specific component
  { path: ':layout/pano', component: PanoRouteComponent },
  {
    //rest are farmlayout components
    path: '', children: [
      //when layout is provided: to route selection
      { path: ':layout', component: RouteSelectionComponent },
      //otherwise, farm selector
      { path: '', component: FarmSelectorComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
