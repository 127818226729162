import { Component, OnInit, HostListener, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Farm } from 'src/app/models/farm';
import { FarmService } from 'src/app/services/farm.service';
import panzoom from 'panzoom';
import { PannerComponent } from 'src/app/shared/panner/panner.component';
import { SidebarComponent, SIDEBAR_WIDTH_PX } from 'src/app/shared/sidebar/sidebar.component';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DropdownData } from 'src/app/shared/ui-dropdown/ui-dropdown.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from 'src/app/contact-form/contact.service';
import { RoutingService } from 'src/app/services/routing.service';

export interface HintElement {
  image: string;
  text: string;
  buttonText: string;
  buttonTextSkip: string;
  arrow: string;
}

@Component({
  selector: 'app-farm-selector',
  templateUrl: './farm-selector.component.html',
  styleUrls: ['./farm-selector.component.scss'],
})
export class FarmSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  SIDEBAR_WIDTH_PX = SIDEBAR_WIDTH_PX;
  //<app-hint image="./assets/ui/icons/hint-drag.svg" text="hints.click-drag" button-text="buttons.shared.understood">
  hints: HintElement[] = [
    {
      image: './assets/ui/icons/hint-drag.svg',
      text: 'hints.click-drag',
      buttonText: 'buttons.shared.understood',
      buttonTextSkip: 'buttons.shared.skip',
      arrow: '',
    },
    {
      image: './assets/ui/icons/hint-click.svg',
      text: 'hints.click-sidebar',
      buttonText: 'buttons.shared.understood',
      buttonTextSkip: 'buttons.shared.skip',
      arrow: 'right',
    },
    {
      image: './assets/ui/icons/hint-click.svg',
      text: 'hints.navigation',
      buttonText: 'buttons.shared.understood',
      buttonTextSkip: '',
      arrow: 'down',
    },
  ];
  hintIndex: number = 0;

  private farms: Farm[];
  herdSizes: number[] = [];
  layouts: string[] = [];
  herdSize: number;
  layout: string;

  url: string = '';

  private farm: Farm;
  image_container_style: object;

  languageDropdownData: DropdownData[];
  selectedLanguage: DropdownData;

  isPhone: boolean = false;

  @ViewChild('content', { static: false }) content: ElementRef;
  @ViewChild('panner', { static: false }) panner: PannerComponent;
  @ViewChild(SidebarComponent, { static: false }) sidebar: SidebarComponent;

  sidebarStartOpen: boolean = true;

  constructor(private farmService: FarmService, private router: Router, private analytics: AnalyticsService, private translate: TranslateService, private contact: ContactService, private rs: RoutingService) {}

  ngOnDestroy() {}

  ngOnInit(): void {
    this.isPhone = this.farmService.isPhone;
    this.sidebarStartOpen = this.rs.sidebarOpenOnInit;

    //init hints
    const sessionVal = sessionStorage.getItem(this.hints_shown_sessionkey);
    if (sessionVal != null && sessionVal == this.hints_shown_sessionkey) this.hintIndex = this.hints.length;
    else this.hintIndex = 0;

    this.contact.HintsOpen.next(this.hintIndex < this.hints.length);

    let startupLanguage = environment.default_language;
    let storedLang = localStorage.getItem(environment.sessionkey_language);
    if (storedLang != undefined && storedLang != '') startupLanguage = storedLang;

    //fill dropdown data
    this.languageDropdownData = [];
    this.selectedLanguage = null;
    environment.languages.forEach((language) => {
      let langEl: DropdownData = {
        icon: './assets/ui/flags/' + language + '.svg',
        label: 'main.languages.' + language,
        internalvalue: language,
      };
      this.languageDropdownData.push(langEl);

      if (language == startupLanguage) {
        this.selectedLanguage = langEl;
      }
    });

    this.initFarmOptions();
  }

  ngAfterViewInit() {
    this.panner.reset();

    if (this.isPhone) {
      this.panner.move_to_percentage(0.35, 0.5);
    }
  }

  onLangChanged(lang: DropdownData) {
    this.translate.use(lang.internalvalue);
    localStorage.setItem(environment.sessionkey_language, lang.internalvalue);
  }

  //            IMAGE FUNCTIONALITY

  //            SIDEBAR FUNCTIONALITY

  initFarmOptions() {
    this.farms = this.farmService.getFarms();

    //fill available herd sizes
    this.farms.forEach((farm) => {
      if (this.herdSizes.indexOf(farm.herdSize) == -1) this.herdSizes.push(farm.herdSize);
    });

    //available layouts will be filled when a farm is selected
    //pre-select the first farm
    this.selectHerdSize(this.farms[0].herdSize);
    this.updateUrl();
  }

  clickedHerdSize(size: number) {
    this.selectHerdSize(size);
    if (this.farmService.isPhone) {

      //close sidebar if user clicked a farm that only has 1 layout
      //stay open if he needs to choose a herd size
      const farms = this.farms.filter((x) => x.herdSize == size);
      if (farms.length > 1) {
      } else this.sidebar.close();
    }
  }

  selectHerdSize(size: number) {
    // if (size == this.herdSize) return

    this.herdSize = size;
    this.layouts = [];
    this.layout = '';
    //get farms with this herd-size
    const farms = this.farms.filter((x) => x.herdSize == size);
    if (farms.length > 1) {
      //more than 1 layout
      farms.forEach((_farm) => {
        this.layouts.push(_farm.layout);
      });

      this.selectLayout(farms[0].layout);
    } else {
      this.selectLayout('');
    }
  }

  clickedLayout(layout: string) {
    this.selectLayout(layout);
    if (this.farmService.isPhone) {
      this.sidebar.close();
    }
  }

  selectLayout(layout: string) {
    this.layout = layout;
    this.updateImage();
    this.updateUrl();
  }

  updateImage() {
    //fetch farm
    const farm = this.farms.find((x) => x.layout == this.layout && x.herdSize == this.herdSize);

    if (farm == undefined) {
      console.error('Error: farm not found!');
      this.image_container_style = {};
    } else {
      //background image
      this.image_container_style = {
        'background-image': "url('" + farm.render_drone + "')",
      };
      if (this.panner) {
        this.panner.reset();
        if(this.isPhone){
          switch(this.herdSize){
            case 60: this.panner.move_to_percentage(0.4, 0.5);
            case 120: this.panner.move_to_percentage(0.3, 0.5);
            case 240: this.panner.move_to_percentage(0.45, 0.5);
            case 480: this.panner.move_to_percentage(0.4, 0.5);
          }
        }
      }
      // else console.warn("can't reset panner - panner not found");
    }
  }

  private readonly hints_shown_sessionkey = 'FARMSELECTOR_HINTS';
  private readonly hints_shown_sessionkey_PANOTOUR = 'PANOTOUR_HINTS';

  clickedHint(index: number) {
    this.hintIndex++;
    this.contact.HintsOpen.next(this.hintIndex < this.hints.length);

    if (this.hintIndex == this.hints.length - 1) {
      //hide sidebar with last hint
      this.sidebar.close();
    }
    if (this.hintIndex == this.hints.length) this.sidebar.open();

    sessionStorage.setItem(this.hints_shown_sessionkey, this.hints_shown_sessionkey);
    sessionStorage.setItem(this.hints_shown_sessionkey_PANOTOUR, this.hints_shown_sessionkey_PANOTOUR);
  }

  clickedHintSkip(index: number) {
    this.hintIndex = this.hints.length;
    sessionStorage.setItem(this.hints_shown_sessionkey, this.hints_shown_sessionkey);
  }

  updateUrl() {
    if (this.layout != '') this.url = this.herdSize.toString() + '-' + this.layout;
    else this.url = this.herdSize.toString();
  }

  OnSidebarStateChange(val: boolean) {}

  backClickedNavigator() {
    //only active in this page when mobile
    this.sidebar.open();
  }

  forwardClickedNavigator() {
    this.rs.navigate(['/', this.url], true);
  }
}
