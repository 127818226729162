<!-- pano viewer -->
<app-pano-viewer class="full-frame pano-viewer" [ngClass]="{'pano-loading' : loadingPano}" #panoviewer (x)="onXChange($event)" (y)="onYChange($event)"
    (config-ready)="onConfigReady()" (texture-loading)="setLoading($event)">

</app-pano-viewer>


<div class="full-frame loading" *ngIf="loadingPano">
    <svg-loading size="50px" animation-duration=2></svg-loading>
</div>

<div *ngIf="dev" class="dev-pointer"></div>
<div *ngIf="dev && tour != undefined" class="devpanel">
    <h2>Dev Panel
        <button (click)="DEV_logtour()">LogJson</button>
    </h2>

    <div>
        X: {{currentX | number: '1.0-1'}}, Y: {{currentY| number: '1.0-1'}}
        <br />
        <button (click)="DEV_setAsEntryRotationForAllSwitches()">
            Set current angle as start-rot for all switches
        </button>
    </div>

    <div class="panos">
        <h3>Scenes</h3>
        <a *ngFor="let scene of tour.scenes" (click)="gotoScene(scene.id)"
            [ngClass]="{'active': currentScene != undefined && currentScene.id == scene.id}">{{scene.id}}</a>
    </div>

    <div class="switches">
        <h3>Switches
            <button (click)="DEV_addSwitch()">Add</button>
        </h3>
        <button (click)="DEV_applySwitch()">update visual</button>

        <div class="sw" *ngFor="let sw of currentScene.switches">
            <div class="row>"><label>to</label><input type="text" [(ngModel)]="sw.to"></div>
            <div class="row"><label>x</label> <input type="text" [(ngModel)]="sw.x"></div>
            <div class="row"><label>y</label> <input type="text" [(ngModel)]="sw.y">
                <div class="row">
                    <button (click)="DEV_switchtoview(sw)">To View</button>
                </div>

            </div>
        </div>
    </div>
</div>