import { EventEmitter, OnDestroy } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ContactService } from 'src/app/contact-form/contact.service';

export const SIDEBAR_WIDTH_PX = 480;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {


  @Input("start-open") startOpen: boolean = true;
  @Input("can-close") canClose: boolean = true;

  @Input("stretch") stretch: boolean = false;

  state: boolean = false;
  animating:boolean = false;

  @Output("state-changed") onStateChanged : EventEmitter<boolean> = new EventEmitter<boolean>();

  timeout? : number = undefined;

  constructor(
    private contactService : ContactService
  ) { }

  ngOnInit(): void {
    this.state = this.startOpen;
    this.onStateChanged.emit(this.state);
    this.contactService.SidebarOpen.next(this.state);
  }

  public isOpen(){
    return this.state;
  }

  public toggle() {
    if(this.canClose)
    return;

    if(this.state)
      this.close();
    else
      this.open();
  }

  public open(){

    if(!this.canClose)
      return;

    this.animating = true;
    this.state = true;
    this.onStateChanged.emit(this.state);
    this.contactService.SidebarOpen.next(this.state);

    if(this.timeout != undefined)
      clearTimeout(this.timeout);

    this.timeout = window.setTimeout(()=>{this.animating = false;}, 500);
  }

  public close(){

    if(!this.canClose)
      return;

    this.animating = true;
    this.state = false;
    this.onStateChanged.emit(this.state);
    this.contactService.SidebarOpen.next(this.state);

    if(this.timeout != undefined)
      clearTimeout(this.timeout);

    this.timeout = window.setTimeout(()=>{this.animating = false;}, 500);

  }

  ngOnDestroy():void{
    this.contactService.SidebarOpen.next(false);
  }
}
