import { EventEmitter } from '@angular/core'
import {
  AfterViewInit,
  Component,
  ElementRef,
  Injectable,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import * as THREE from 'three'
import { map } from 'rxjs/operators'
import {
  PanoViewerConfig,
  ClickableUI,
  OnDoneLoadingEvent,
} from '../models/pano-viewer-config'

import {Text} from 'troika-three-text'

///
@Component({
  selector: 'app-pano-viewer',
  templateUrl: './pano-viewer.component.html',
  styleUrls: ['./pano-viewer.component.scss'],
})
export class PanoViewerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('rendererCanvas', { static: true })
  rendererCanvas: ElementRef<HTMLCanvasElement>

  private config: PanoViewerConfig = null

  @Output('config-ready') configReady = new EventEmitter()
  @Output('texture-loading') onTextureLoadingStateChange = new EventEmitter<
    boolean
  >()

  @Output('x') onXChange = new EventEmitter<number>()
  @Output('y') onYChange = new EventEmitter<number>()

  private canvas: HTMLCanvasElement
  private renderer: THREE.WebGLRenderer
  private camera: THREE.PerspectiveCamera
  private scene: THREE.Scene
  private clickableGroup: THREE.Group
  private sphereMesh: THREE.Mesh
  private raycaster: THREE.Raycaster
  private textureLoader: THREE.TextureLoader = new THREE.TextureLoader()
  private clock: THREE.Clock

  private frameId: number = null

  //rotate camera stuff
  private x: number = 180
  private y: number = 0

  private DEFAULT_X: number = 180
  private DEFAULT_Y: number = 0

  private CLICK_TIMEOUT = 250

  private SPHERE_RADIUS = 5
  private CLICKABLE_DISTANCE = 4

  private readyForInit: boolean = false
  private spawnedClickables: ClickableUI[] = []
  private clickablesVisible: boolean = true
  private hoverElement: ClickableUI

  //          ANIMS

  //          PUBLICS

  //Apply a new config
  public initialize(userConfig: PanoViewerConfig) {
    this.config = PanoViewerConfig.build(userConfig)

    if (this.readyForInit) this.init()
  }

  //Set the given path as panorama background (equi)
  public setPano(path: string, onDone: OnDoneLoadingEvent) {
    this._setPano(path, onDone)
  }

  //clear the current clickables and spawn a new set
  public addClickableElements(elements: ClickableUI[]) {
    this._addClickableElements(elements)
  }

  //set the x-lookat in degrees (0-359)
  public setX(x: number) {
    this._setXY(x, this.y)
  }

  //set the y-lookat in degrees (0-180)
  public setY(y: number) {
    this._setXY(this.x, y)
  }

  //set the x-y lookat in degrees
  public setXY(x: number, y: number) {
    this._setXY(x, y)
  }

  public clearClickables() {
    this._clearClickables()
  }

  public setClickableVisibility(state: boolean) {
    this._setClickableVisibility(state)
  }

  public showClickables() {
    this._setClickableVisibility(true)
  }

  public hideClickables() {
    this._setClickableVisibility(false)
  }

  //          INTERNALS

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.readyForInit = true

    if (this.config != null) this.init()
  }

  private init() {
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = this.rendererCanvas.nativeElement

    this.addEvents()

    this.createScene()

    this.preloadClickableMaterials()

    this.animate()

    this.configReady.emit()
  }

  ngOnDestroy(): void {
    this._clearClickables();
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId)
    }

    this.removeEvents()
  }

  private createScene(): void {
    this.clock = new THREE.Clock()

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true, // transparent background
      antialias: true, // smooth edges
    })
    this.renderer.setSize(window.innerWidth, window.innerHeight)

    // create the scene
    this.scene = new THREE.Scene()

    this.camera = new THREE.PerspectiveCamera(
      this.config.fov,
      window.innerWidth / window.innerHeight,
      0.1,
      1000,
    )
    this.camera.target = new THREE.Vector3(0, 0, 0)

    this.scene.add(this.camera)

    this.addSphereToScene()

    //empty for clickable elements
    this.clickableGroup = new THREE.Group()
    this.scene.add(this.clickableGroup)

    this.raycaster = new THREE.Raycaster()
  }

  private addSphereToScene() {
    const sphereMaterial = new THREE.MeshBasicMaterial()

    //create sphere and apply scale -1 to invert faces
    var geometry = new THREE.SphereGeometry(this.SPHERE_RADIUS, 100, 100)
    geometry.scale(-1, 1, 1)

    this.sphereMesh = new THREE.Mesh(geometry, sphereMaterial)
    this.scene.add(this.sphereMesh)
  }

  private preloadClickableMaterials() {
    for (
      let index = 0;
      index < this.config.clickableMaterials.length;
      index++
    ) {
      const element = this.config.clickableMaterials[index]

      const texture = new THREE.TextureLoader().load(element.image)
      const material = new THREE.MeshBasicMaterial({ map: texture })

      material.side = THREE.DoubleSide
      material.transparent = true

      this.config.clickableMaterials[index].texture = texture
      this.config.clickableMaterials[index].material = material
    }
  }

  startLoadTime: Date = new Date()
  private _setPano(path: string, onDone: OnDoneLoadingEvent) {

    this.clearClickables()

    this.startLoadTime = new Date()

    this.onTextureLoadingStateChange.emit(true)

    //yield a moment before setting tex
    this.textureLoader.load(
      path,
      //done callback
      (texture) => {
        const now = new Date()
        //result is in milliseconds - so convert to seconds
        const duration = (now.getTime() - this.startLoadTime.getTime()) / 1000
        let waitTime = 0

        if (duration < this.config.transitionTime ?? 1) {
          waitTime = this.config.transitionTime ?? 1 - duration
        }

        window.setTimeout(() => {
          this.onTextureLoadingStateChange.emit(false)
          this.sphereMesh.material.map = texture
          this.sphereMesh.material.needsUpdate = true
          // this.setXY(this.DEFAULT_X, this.DEFAULT_Y);
          if (onDone != null) onDone()
        }, waitTime*1000)
      },
    )
  }

  private _clearClickables() {
    this.hoverElement = null

    this.spawnedTextElements.forEach((textElement)=>{
      //don't remove, it will be removed by removing the parent (spawnedClickables below)
      //but do dispose
      textElement.dispose();
    })
    this.spawnedClickables.forEach((oldElement) => {
      if (oldElement.mesh != null) {
        oldElement.mesh.geometry.dispose()
        this.clickableGroup.remove(oldElement.mesh)
        this.scene.remove(oldElement.mesh)
      }
      oldElement.mesh = null
    })


    this.spawnedClickables = []
  }

  private _setClickableVisibility(value: boolean) {
    this.clickablesVisible = value

    this.spawnedClickables.forEach((element) => {
      if (element.mesh != null) element.mesh.visible = value
    })
  }

  private _addClickableElements(elements: ClickableUI[]) {
    //clean existing
    this.clearClickables()

    //add new
    elements.forEach((element) => {
      //find material data
      let materialData = this.config.clickableMaterials.find(
        (x) => x.id == element.clickable.materialID,
      )

      if (materialData == null) {
        materialData = this.config.clickableMaterials[0]
      }

      const plane = new THREE.PlaneBufferGeometry(
        materialData.width,
        materialData.height,
      )
      const mesh = new THREE.Mesh(plane, materialData.material)

      this.clickableGroup.add(mesh)
      element.mesh = mesh
      element.mesh.visible = this.clickablesVisible
      this.spawnedClickables.push(element)

      //position mesh along edge of sphere based on x,y deg
      const phi = THREE.MathUtils.degToRad(90 - element.clickable.y)
      const theta = THREE.MathUtils.degToRad(element.clickable.x)

      const xpos = this.CLICKABLE_DISTANCE * Math.sin(phi) * Math.cos(theta)
      const ypos = this.CLICKABLE_DISTANCE * Math.cos(phi)
      const zpos = this.CLICKABLE_DISTANCE * Math.sin(phi) * Math.sin(theta)

      mesh.position.y = ypos
      mesh.position.x = xpos
      mesh.position.z = zpos

      mesh.lookAt(0, 0, 0)

      mesh.scale.set(element.clickable.scale, element.clickable.scale, element.clickable.scale)

      const hoverScale = element.clickable.scale * 1.2
      element.mixer = new THREE.AnimationMixer(mesh)
      const anim = PanoViewerComponent.CreatePulsationAnimation(
        0.25,
        element.clickable.scale,
        hoverScale,
      )
      const anim2 = PanoViewerComponent.CreatePulsationAnimation(
        0.25,
        hoverScale,
        element.clickable.scale,
      )
      mesh.animations = [anim, anim2]

      const fontData = element.clickable.overrideFontdata != undefined ? element.clickable.overrideFontdata : this.config.fontData;
      //add label
      if (element.clickable.label_top != '') {

        const textTop = new Text();
        mesh.add(textTop);

        textTop.text = element.clickable.label_top;
        textTop.fontSize = fontData.fontSize;
        textTop.font = fontData.fontTTF;
        textTop.position.set(0,materialData.height+0.1,0);
        textTop.color = fontData.fontColor;
        textTop.anchorX = "center";
        textTop.outlineColor = fontData.outlineColor;
        textTop.outlineWidth = fontData.outlineWidth
        textTop.outlineBlur = fontData.outlineBlur
        textTop.outlineOpacity =fontData.outlineOpacity
        textTop.sync();

        this.spawnedTextElements.push(textTop);
      }

      //add label
      if (element.clickable.label_center != '') {
        
        const textCenter = new Text();
        mesh.add(textCenter);

        textCenter.text = element.clickable.label_center;
        textCenter.font = fontData.fontTTF;
        textCenter.fontSize = fontData.fontSizeCenter;
        textCenter.position.set(0,fontData.centerY,0.1);
        textCenter.color = fontData.fontColorCenter;
        textCenter.anchorX = "center";
        textCenter.anchorY = "center";
        textCenter.sync();

        this.spawnedTextElements.push(textCenter);
      }
    })
  }

  spawnedTextElements : Text[] = [];

  static CreatePulsationAnimation(duration, normalScale, pulseScale) {
    const times = [0, duration]
    const values = []

    new THREE.Vector3()
      .set(normalScale, normalScale, normalScale)
      .toArray(values, values.length)
    new THREE.Vector3()
      .set(pulseScale, pulseScale, pulseScale)
      .toArray(values, values.length)
    // new THREE.Vector3(normalScale, normalScale, normalScale),
    // new THREE.Vector3(pulseScale, pulseScale, pulseScale)
    //   normalScale, pulseScale
    // ];
    const trackName = '.scale'
    const track = new THREE.VectorKeyframeTrack(trackName, times, values)

    const clip = new THREE.AnimationClip(null, duration, [track])

    return clip
  }

  //in degrees
  private _setXY(x: number, y: number) {
    this.x = x
    this.y = y

    this.clampXY()

    this.onXChange.emit(this.x)
    this.onYChange.emit(this.y)

    this.updateCamera()
  }

  private clampXY() {
    if (this.x < 0) this.x = 359 + this.x
    if (this.x > 359) this.x -= 359

    this.y = Math.max(-85, Math.min(85, this.y))
  }

  private updateCamera() {
    this.clampXY()

    //look towards x, y angle
    //convert x,y angles to world position for lookat
    const phi = THREE.MathUtils.degToRad(90 - this.y)
    const theta = THREE.MathUtils.degToRad(this.x)

    this.camera.target.x = this.SPHERE_RADIUS * Math.sin(phi) * Math.cos(theta)
    this.camera.target.y = this.SPHERE_RADIUS * Math.cos(phi)
    this.camera.target.z = this.SPHERE_RADIUS * Math.sin(phi) * Math.sin(theta)

    this.camera.lookAt(this.camera.target)
  }

  private animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.render()
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          this.render()
        })
      }

      window.addEventListener('resize', () => {
        this.resize()
      })
    })
  }

  private render(): void {
    //cancel running animation and create a new one
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId)
    }

    this.frameId = requestAnimationFrame(() => {
      this.frameUpdateCall()
      this.render()
    })

    this.renderer.render(this.scene, this.camera)
  }

  private resize(): void {
    const width = window.innerWidth
    const height = window.innerHeight

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()

    this.renderer.setSize(width, height)
  }

  frameUpdateCall() {
    const delta = this.clock.getDelta()

    //this is a pre-render call that is called each frame before THREE renders.

    this.spawnedClickables.forEach((element) => {
      if (element.mixer != undefined) element.mixer.update(delta)
    })
  }

  //              MOUSE EVENTS

  private addEvents() {
    if (this.canvas != undefined) {
      this.canvas.addEventListener('touchstart', this._mouseDown.bind(this))
      this.canvas.addEventListener('mousedown', this._mouseDown.bind(this))

      this.canvas.addEventListener('touchmove', this._mouseMove.bind(this))
      this.canvas.addEventListener('mousemove', this._mouseMove.bind(this))

      this.canvas.addEventListener('touchend', this._mouseUp.bind(this))
      this.canvas.addEventListener('mouseup', this._mouseUp.bind(this))

      // this.canvas.addEventListener('mouseenter', this._mouseEnter.bind(this));
      this.canvas.addEventListener('mouseleave', this._mouseLeave.bind(this))
    }
  }

  private removeEvents() {
    if (this.canvas != undefined) {
      this.canvas.removeEventListener('touchstart', this._mouseDown.bind(this))
      this.canvas.removeEventListener('mousedown', this._mouseDown.bind(this))

      this.canvas.removeEventListener('touchmove', this._mouseMove.bind(this))
      this.canvas.removeEventListener('mousemove', this._mouseMove.bind(this))

      this.canvas.removeEventListener('touchend', this._mouseUp.bind(this))
      this.canvas.removeEventListener('mouseup', this._mouseUp.bind(this))

      // this.canvas.removeEventListener('mouseenter', this._mouseEnter.bind(this));
      this.canvas.removeEventListener('mouseleave', this._mouseLeave.bind(this))
    }
  }

  private down: boolean
  private downTime: number

  private startX: number
  private startY: number
  private startXMouse: number
  private startYMouse: number

  private downHitObject: ClickableUI

  private _mouseDown($event: MouseEvent | TouchEvent) {
    this.down = true
    this.downTime = +new Date()

    this.startX = this.x
    this.startY = this.y

    if ($event instanceof MouseEvent) {
      let e = <MouseEvent>$event
      this.startXMouse = e.clientX
      this.startYMouse = e.clientY
    } else if ($event instanceof TouchEvent) {
      let e = <TouchEvent>$event
      this.startXMouse = e.changedTouches[0].pageX
      this.startYMouse = e.changedTouches[0].pageY
    }

    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    const mouse = new THREE.Vector2(0, 0)
    mouse.x = (this.startXMouse / window.innerWidth) * 2 - 1
    mouse.y = -(this.startYMouse / window.innerHeight) * 2 + 1
    this.downHitObject = this.getRaycastObject(mouse)
  }

  private _mouseMove($event: MouseEvent | TouchEvent) {
    let currentXMouse = 0
    let currentYMouse = 0

    if ($event instanceof MouseEvent) {
      let e = <MouseEvent>$event
      currentXMouse = e.clientX
      currentYMouse = e.clientY
    } else if ($event instanceof TouchEvent) {
      let e = <TouchEvent>$event
      currentXMouse = e.changedTouches[0].pageX
      currentYMouse = e.changedTouches[0].pageY
    }

    if (this.down) {
      //view horizontal and vertical in degrees that are visible from the cameraview
      const aspect = this.camera.aspect
      const verAngle = this.camera.fov
      const horAngle = aspect * verAngle

      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight

      const movementPercentageX =
        (currentXMouse - this.startXMouse) / windowWidth
      const movementPercentageY =
        (currentYMouse - this.startYMouse) / windowHeight

      const deltaX = movementPercentageX * horAngle * this.config.orbitSpeed
      const deltaY = movementPercentageY * verAngle * this.config.orbitSpeed

      this.setXY(this.startX - deltaX, this.startY + deltaY)
    } else {
      //hovering check
      // calculate mouse position in normalized device coordinates
      // (-1 to +1) for both components
      const mouse = new THREE.Vector2(0, 0)
      mouse.x = (currentXMouse / window.innerWidth) * 2 - 1
      mouse.y = -(currentYMouse / window.innerHeight) * 2 + 1
      const hitResult = this.getRaycastObject(mouse)

      if (hitResult != null) {
        //hovering
        if (hitResult != this.hoverElement) {
          if (this.hoverElement != null) {
            this.playHoverAnim(this.hoverElement, 1)
          }

          this.hoverElement = hitResult

          this.playHoverAnim(this.hoverElement, 0)
        }
      } else {
        //unhovering
        if (this.hoverElement != null) {
          this.playHoverAnim(this.hoverElement, 1)
        }

        this.hoverElement = null
      }
    }
  }

  playHoverAnim(el: ClickableUI, index: number) {
    const alternateIndex = index == 0 ? 1 : 0
    const oldAction = el.mixer.clipAction(el.mesh.animations[alternateIndex])
    oldAction.stop()
    oldAction.reset()

    var action = el.mixer.clipAction(el.mesh.animations[index])
    action.setLoop(THREE.LoopOnce)
    action.clampWhenFinished = true
    action.reset()
    action.play()
  }

  private _mouseUp($event: MouseEvent | TouchEvent) {
    this.down = false

    const now = +new Date()
    const elapsed = now - this.downTime

    if (elapsed <= this.CLICK_TIMEOUT) {
      this._mouseClick($event)
    }
  }

  private _mouseLeave($event) {
    if (this.down) {
      this.down = false
    }
  }

  private _mouseClick($event: MouseEvent | TouchEvent) {
    let currentXMouse = 0
    let currentYMouse = 0

    if ($event instanceof MouseEvent) {
      let e = <MouseEvent>$event
      currentXMouse = e.clientX
      currentYMouse = e.clientY
    } else if ($event instanceof TouchEvent) {
      let e = <TouchEvent>$event
      currentXMouse = e.changedTouches[0].pageX
      currentYMouse = e.changedTouches[0].pageY
    }

    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    const mouse = new THREE.Vector2(0, 0)
    mouse.x = (currentXMouse / window.innerWidth) * 2 - 1
    mouse.y = -(currentYMouse / window.innerHeight) * 2 + 1
    const hitResult = this.getRaycastObject(mouse)

    if (hitResult != null && hitResult == this.downHitObject) {
      if (hitResult.onClick != null) {
        hitResult.onClick()
        $event.preventDefault();
      }
    }
  }

  getRaycastObject(mouse: THREE.Vector2): ClickableUI {
    this.raycaster.setFromCamera(mouse, this.camera)
    const intersects = this.raycaster.intersectObject(this.clickableGroup, true)

    if (intersects.length > 0) {
      for (let index = 0; index < intersects.length; index++) {
        let clickable = this.spawnedClickables.find(
          (x) => x.mesh == intersects[index].object,
        )
        if (clickable != null) {
          return clickable
        }
      }
    }
    return null
  }
}
