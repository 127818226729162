import { Component, Input, OnInit } from '@angular/core';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { ContactService } from './contact.service';
import { Subscription } from 'rxjs';
import { FarmService } from '../services/farm.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { betterEmailValidator } from '../_submodules/letink.shared.angular.core/functions/form-validator.functions';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  sidebarOpen : boolean;
  infotileOpen : boolean;
  hintsOpen : boolean;
  lastPanoPopupOpen : boolean;
  
  fullframeSidebar : boolean;

  contactFormOpen:boolean = false;
  formGroup : FormGroup;
  forceError : boolean = false;
  showConfirm : boolean = false;

  isPano : boolean = false;

  constructor(
    service : ContactService,
    private farm : FarmService,
    private http : HttpClient,
    private route : ActivatedRoute,
    private router : Router
  ) { 
    this.router.events.subscribe((val)=>{
      if(val instanceof NavigationEnd){
        this.isPano = val.url.indexOf('pano') > -1;
      }
    })
    this.fullframeSidebar = this.farm.isPhone;
    service.SidebarOpen.subscribe((open)=>{
      this.sidebarOpen = open;
    })
    service.InfotileOpen.subscribe((open)=>{
      this.infotileOpen = open;
    })
    service.HintsOpen.subscribe((open)=>{
      this.hintsOpen = open;
    })
    service.LastPanoPopupOpen.subscribe((open)=>{
      this.lastPanoPopupOpen = open;
    })

    this.formGroup = new FormGroup({});
    this.createEmptyForm();

  }

  bodyClick($event : Event){
    $event.preventDefault();
  }

  showContactButton() : boolean{
    if(this.fullframeSidebar){
      //mobile
      return !this.sidebarOpen && !this.infotileOpen && !this.lastPanoPopupOpen && !this.hintsOpen;
    }else{
      return !this.infotileOpen && !this.lastPanoPopupOpen && !this.hintsOpen;
    }
  }

  createEmptyForm(){
    this.formGroup = new FormGroup({
      message : new FormControl('', [Validators.required]),
      first_name : new FormControl('', [Validators.required]),
      last_name : new FormControl('', [Validators.required]),
      email : new FormControl('', [betterEmailValidator()]),
      company_name : new FormControl('', []),
      phone_number : new FormControl('', []),
      zip_code : new FormControl('', []),
      city : new FormControl('', []),
      country : new FormControl('', []),
      allow_marketing : new FormControl(false, []),
    })

    this.forceError = false;
  }

  ngOnInit(): void {
  }

  clickedContactButton(){
    this.contactFormOpen=true;
    this.createEmptyForm();
  }

  showRequiredError() : boolean{
    
    return (this.formGroup.get('message').touched
    || this.formGroup.get('first_name').touched
    || this.formGroup.get('last_name').touched)
    || this.forceError;
  }

  formInvalidDueToRequired() : boolean{
    return (
       (!this.formGroup.get('message')?.valid
      ||!this.formGroup.get('first_name')?.valid
      ||!this.formGroup.get('last_name')?.valid
    )
    )
  }


  showNoMailOrPhoneError() : boolean{
    
    return (this.formGroup.get('email').touched
    ||this.formGroup.get('phone_number').touched)
    || this.forceError;
    
  }
  formInvalidDueToNoMailOrPhone() : boolean{
    const email = this.formGroup.get('email');
    const phone = this.formGroup.get('phone_number');

    const valid = 
      (email.value.trim() == "" && phone.value.trim() != "")
      || (email.value.trim() != "" && phone.value.trim() == "")
    || (email.value.trim() != "" && phone.value.trim() != "");


      if(!valid){
        console.log("email/phone combi not valid");
        console.log(email);
        console.log(phone);

        this.formGroup.setErrors({emailOrPhone: "Email or phone"})
        email.setErrors({emailOrPhone: "Email or phone"})
        phone.setErrors({emailOrPhone: "Email or phone"})
      }else{
        if(email.errors != null)
          email.errors["emailOrPhone"] = null;
        phone.setErrors(null)
      }
      return !valid;
  }

  showIncorrectMailError() : boolean{
    const mail = this.formGroup.get('email');
    return mail.errors != null && mail.errors.email != null;

  }
  formInvalidDueToIncorrectEmail() : boolean{
    const mail = this.formGroup.get('email');
    let invalid =  mail.errors != null && mail.errors.email != null;
    let filledIn =mail.value.trim() != "" 
    return invalid && filledIn;
  }





   toggle(formControl : FormControl){
    formControl.setValue(!formControl.value);
   }

   busy : boolean;
  clickedSendButton($event : Event){

    if(this.formInvalidDueToNoMailOrPhone() 
    || this.formInvalidDueToRequired()
    || this.formInvalidDueToIncorrectEmail()){
      this.forceError = true;
    }else{
      this.forceError = false;
      this.busy = true;

      this.http.post("./api/index.php", this.formGroup.value).subscribe((result)=>{
        this.showConfirm = true;
        this.busy = false;
      }, (error)=>{
        console.error("got error response:");
        console.error(error);
        this.busy = false;
      })
    }

    $event.preventDefault();
  }

  clickedConfirm(){
    this.clickedClose();
  }

  clickedClose(){
    this.contactFormOpen=false;
    this.forceError = false;
    this.showConfirm = false;
    this.createEmptyForm();
  }

}


