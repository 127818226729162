import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'evenodd',
})
export class EvenoddPipe implements PipeTransform {
  transform(value: any[], filter: string): any[] {
    if (value == undefined || value.length == 0) return value

    return value.filter((item, i) => {
      return filter === 'even' ? i % 2 === 1 : i % 2 === 0
    })
  }
}
