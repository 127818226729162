import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  SidebarOpen : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  InfotileOpen : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  HintsOpen : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  LastPanoPopupOpen : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  constructor() { }
}
