import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PanoInfoTile } from 'src/app/models/pano-infotile';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import {
  ThreeSixtyConfig,
  DisplayMode,
} from 'src/app/shared/three-sixty-slider/three-sixty-config.model';
import { WebpsupportService } from 'src/app/shared/three-sixty-slider/webpsupport.service';
import { ThreeSixtySliderComponent } from 'src/app/shared/three-sixty-slider/three-sixty-slider.component';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ScrollerService } from 'src/app/services/scroller.service';

import { easing } from 'ts-easing';
import { FarmService } from 'src/app/services/farm.service';

export interface uspcontent {
  title: string;
  desc: string;
  thumb: string;
  media: string;
  mediatype: string;
  url: string;
  class: string;
}
@Component({
  selector: 'app-infotile',
  templateUrl: './infotile.component.html',
  styleUrls: ['./infotile.component.scss'],
})
export class InfotileComponent implements OnInit, OnChanges, OnDestroy {
  uspindexleft = [];
  uspindexright = [];
  @ViewChild('slider', { static: false }) slider: ThreeSixtySliderComponent;
  @ViewChild('tile', { static: false }) tile: ElementRef;
  webp_sub: Subscription;

  @Input() data?: PanoInfoTile;
  @Input() open: boolean = false;

  @Output() onClose = new EventEmitter();

  selectedUspIndex?: number = undefined;
  selectedUspData?: uspcontent = undefined;

  //focussed items
  focus_video: string = '';
  focus_pdf?: SafeUrl = undefined;
  focus_link: SafeUrl = undefined;

  //360 stuff
  dragging: boolean = false;
  slider_preload_percentage: number = 0;
  tested: boolean = false;
  config_ready: boolean = false;
  config?: ThreeSixtyConfig = undefined;
  images_loaded: boolean = false;

  default_config: ThreeSixtyConfig = {
    display_mode: DisplayMode.Contain,
    image_count_x: 36,
    image_count_y: 5,
    image_count_y_start: 4,
    image_count_x_start: 0,
    image_width: 1920,
    image_height: 1920,
    image_path_prefix: '',
    image_filename_prefix: '',
    image_filename_extension: '.webp',
    webp_fallback: '.png',
    single_numbering: false,
    startindex_y: 0,
    startindex_x: 15,
    x_speed: 1,
    y_speed: 2,
    x_wrap: true,
    y_wrap: false,
    allow_zoom: false,
    zoom_max: 1,
    autoplay_speed: 15,
    leading_zeroes: false,
    number_string_length_leading_zeros: 3,
    pauses: [],
  };

  isPhone : boolean = false;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private webp: WebpsupportService,
    private translate: TranslateService,
    private analytics: AnalyticsService,
    private scroll: ScrollerService,
    private farmservice : FarmService
  ) {
    this.isPhone = farmservice.isPhone;
    webp.test();
    this.webp_sub = webp.onTested.subscribe(() => {});
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.webp_sub != undefined) this.webp_sub.unsubscribe();
  }

  clickedClose() {
    this.onClose.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.open && this.data != undefined) {
      //init stuff
      this.distributeUSPs();
      this.selectUsp(undefined);
      const tileTitle = this.getTileTranslation('title');
      this.analytics.send('infotile', tileTitle);
    }
  }

  distributeUSPs() {
    //max 8, but we don't know how many
    //can't fetch listsize in ngx-translate, so we're just going to translate the titles
    this.uspindexleft = [];
    this.uspindexright = [];
    let count = 0;

    for (let i = 0; i < 8; i++) {
      const title = this.getUspTranslation(i, 'title');
      if (title != '') count++;
    }
    let leftCount = Math.ceil(count / 2);
    for (let i = 0; i < 8; i++) {
      if (i < leftCount) this.uspindexleft.push(i);
      else this.uspindexright.push(i);
    }
  }

  time: number = 0;
  from: number = 0;
  scrolltime = 1;
  readonly frametime = 1 / 30;
  doScrollToTop() {
    this.time = 0;
    if (this.tile == undefined) return;

    const html = <HTMLDivElement>this.tile.nativeElement;
    this.from = html.scrollTop;
    this.doScrollToTopFrame();
  }
  doScrollToTopFrame() {
    this.time += this.frametime;
    let perc = this.time / this.scrolltime;
    let done = false;
    if (perc > 1) {
      perc = 1;
      done = true;
    }
    const html = <HTMLDivElement>this.tile.nativeElement;
    html.scrollTop = this.from - this.from * easing.inOutSine(perc);

    if (!done) {
      setTimeout(() => {
        this.doScrollToTopFrame();
      }, this.frametime * 1000);
    }
  }

  selectUsp(index?: number) {
    this.selectedUspIndex = index;

    // this.scroll.scroll('#infotile-title');
    this.doScrollToTop();

    if (this.selectedUspIndex != undefined) {
      this.selectedUspData = {
        title: this.getUspTranslation(index, 'title'),
        desc: this.getUspTranslation(index, 'desc'),
        thumb: this.getUspTranslation(index, 'thumb'),
        media: this.getUspTranslation(index, 'media'),
        mediatype: this.getUspTranslation(index, 'mediatype'),
        url: this.getUspTranslation(index, 'url'),
        class: this.getUspTranslation(index, 'class'),
      };
    } else {
      const desc = this.getTileTranslation('desc');
      const media = this.getTileTranslation('media');

      if (desc == '' && media == '') {
        this.selectUsp(0);
      } else {
        this.selectedUspData = {
          title: this.getTileTranslation('title'),
          desc: desc,
          media: media,
          thumb: this.getTileTranslation('thumb'),
          mediatype: this.getTileTranslation('mediatype'),

          url: '',
          class: this.getUspTranslation(index, 'class'),
        };
      }
      if (this.selectedUspData.mediatype == '')
        this.selectedUspData.mediatype = 'image';
    }
  }

  initWebPro() {
    // let config = <ThreeSixtyConfig>(
    //   JSON.parse(JSON.stringify(this.default_config))
    // )
    // config.image_path_prefix = this.selectedUsp.media.path
    // window.setTimeout(() => {
    //   this.slider.applyConfig(config)
    // }, 100)
  }

  onImagesLoaded(loaded: boolean) {
    this.images_loaded = loaded;
  }

  onDownChange(down: boolean) {
    this.dragging = down;
  }

  getTileTranslation(suffix: string): string {
    const id = 'products.' + this.data.translationid + '.' + suffix;
    const translation = this.translate.instant(id);

    //failed translation
    if (translation == id) return '';

    return translation;
  }

  getUspTranslation(uspindex: number, suffix: string): string {
    const id =
      'products.' +
      this.data.translationid +
      '.usps.' +
      uspindex +
      '.' +
      suffix;
    const translation = this.translate.instant(id);

    //failed translation
    if (translation == id) return '';

    return translation;
  }

  clickedLink(url: string) {
    // this.analytics.send('infotile_link_clicked', url);
    this.focus_link = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  clickedGeaDealer() {
    const url = this.translate.instant('main.gea-dealer.button-url');
    window.open(url, '_blank');
    this.analytics.send('find_a_dealer', url);
    //this.focus_link = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  clickedPlayVideo(media: string) {
    this.analytics.send('infotile_media_video', media);
    this.focus_video = 'assets/data/infotile-content/videos/' + media;
  }

  clickedPlayPDF(media: string) {
    this.analytics.send('infotile_media_pdf', media);
    const pdfpath = 'assets/data/infotile-content/pdfs/' + media;

    this.focus_link = this.sanitizer.bypassSecurityTrustResourceUrl(pdfpath);

    // this.http.get(pdfpath, { responseType: 'blob' }).subscribe((data) => {
    //   this.focus_pdf = this.sanitizer.bypassSecurityTrustResourceUrl(
    //     URL.createObjectURL(data)
    //   );
    // });
  }

  closeFocus() {
    this.focus_video = '';
    this.focus_pdf = undefined;
    this.focus_link = undefined;
  }
}
