import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppBaseComponent } from './base/app-base/app-base.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FarmSelectorComponent } from './pages/farm-selector/farm-selector.component';
import { RouteSelectionComponent } from './pages/route-selection/route-selection.component';
import { PanoRouteComponent } from './pages/pano-route/pano-route.component';
import { FarmService } from './services/farm.service';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { UiDropdownComponent } from './shared/ui-dropdown/ui-dropdown.component';
import { HintComponent } from './shared/hint/hint.component';
import { FadeVisibleDirective } from './directives/fade-visible.directive';
import { PannerComponent } from './shared/panner/panner.component';
import { NavigatorbarComponent } from './shared/navigatorbar/navigatorbar.component';
import { PanoViewerComponent } from './shared/panoSystem/pano-viewer/pano-viewer.component';
import { TourComponent } from './shared/panoSystem/tour.component';
import { InfotileComponent } from './pages/pano-route/infotile/infotile.component';
import { EvenoddPipe } from './pipes/evenodd.pipe';
import { VideoComponent } from './shared/video/video.component';
import { ThreeSixtySliderComponent } from './shared/three-sixty-slider/three-sixty-slider.component';
import { WebpsupportService } from './shared/three-sixty-slider/webpsupport.service';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxGa4Module } from '@kattoshi/ngx-ga4';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
      {prefix: "./assets/translations/langs/", suffix: ".json"},
        {prefix: "./assets/translations/core/", suffix: ".json"},
        {prefix: "./assets/translations/route-selection/", suffix: ".json"},
        {prefix: "./assets/translations/voice-overs/", suffix: ".json"},
        {prefix: "./assets/translations/products/", suffix: ".json"},
        {prefix: "./assets/translations/cookie-consent/", suffix: ".json"},
    ]);
}

@NgModule({
  declarations: [
    AppBaseComponent,
    FarmSelectorComponent,
    RouteSelectionComponent,
    PanoRouteComponent,
    SidebarComponent,
    UiDropdownComponent,
    HintComponent,
    FadeVisibleDirective,
    PannerComponent,
    NavigatorbarComponent,
    PanoViewerComponent,
    TourComponent,
    InfotileComponent,
    EvenoddPipe,
    VideoComponent,
    ThreeSixtySliderComponent,
    CookieConsentComponent,
    ContactFormComponent,
  ],
  imports: [
    BrowserModule,
    NgxGa4Module.forRoot({  /*measurementId: 'G-DBBV8L44TY'*/}),
    AppRoutingModule,
    HttpClientModule,
    NgxPageScrollCoreModule.forRoot({duration: 500,}),
    NgxPageScrollModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader, useFactory: HttpLoaderFactory , deps: [HttpClient]
      }
    }),
  ],
  providers: [FarmService,WebpsupportService],
  bootstrap: [AppBaseComponent]
})
export class AppModule { }
