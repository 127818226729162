import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as THREE from 'three';
import { ClickableUI } from './models/pano-viewer-config';
import { Clickable, Scene, Shortcut, Switch, Tour } from './models/tour';
import { PanoViewerComponent } from './pano-viewer/pano-viewer.component';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
})
export class TourComponent implements OnInit, AfterViewInit {
  @Output('sceneChanged') sceneChanged = new EventEmitter<string>();
  @Output('clickableClicked') clickableClicked = new EventEmitter<Clickable>();
  @Output('moved') moved = new EventEmitter();

  @ViewChild('panoviewer', { static: true })
  panoviewer: PanoViewerComponent;
  infotileVisible: boolean = false;
  loadingPano: boolean = false;

  tour?: Tour;
  currentScene?: Scene;

  currentX: number;
  currentY: number;

  //shows dev panel to edit tour
  // dev: boolean = true;
  dev: boolean = false

  filterSwitches: string = '';
  filterOtherClickables: string = '';

  constructor(private ref: ChangeDetectorRef, private http: HttpClient) {}

  ngOnInit(): void {}

  // Filter clickables by materialid
  public setFilter(switches: string, other: string) {
    this.filterSwitches = switches;
    this.filterOtherClickables = other;
  }

  public initTour(tour: Tour) {
    tour = Tour.build(tour);
    this.tour = tour;
    this.panoviewer.initialize(this.tour.basicConfig);
  }

  onXChange(x : number){
    this.currentX = x
    if(this.moved != undefined)
      this.moved.emit();
  }

  onYChange(y : number){
    this.currentY = y
    if(this.moved != undefined)
      this.moved.emit();
  }

  ngAfterViewInit(): void {}

  getScene(id: string): Scene | undefined {
    if (this.tour == undefined) return undefined;

    return this.tour.scenes.find((x) => x.id == id);
  }

  gotoScene(
    id: string,
    x: number | undefined = undefined,
    y: number | undefined = undefined
  ) {
    const scene = this.getScene(id);
    if (scene != undefined) {
      this.currentScene = scene;
      this.panoviewer.setPano(scene.filename, () => {
        this.loadClickablesForScene(this.currentScene);

        if (x != undefined && y != undefined) {
          this.panoviewer.setXY(x, y);
        }else{
          this.panoviewer.setXY(scene.startup_x, scene.startup_y)
        }

        this.sceneChanged.emit(id);
      });
    }
  }

  loadClickablesForScene(scene: Scene) {
    this.panoviewer.clearClickables();

    let clickables: ClickableUI[] = [];

    scene.switches.forEach((sw) => {
      if (this.filterSwitches == '' || this.filterSwitches == sw.materialID) {
        const el: ClickableUI = new ClickableUI();
        el.clickable =  Object.assign(new Clickable(), sw);

        el.onClick = () => {
          this.gotoScene(sw.to, sw.view_x, sw.view_y);
        };

        clickables.push(el);
      }
    });


    const anyFulltour = scene.other_clickables.find(x => x.materialID === 'fulltour') !== undefined;

    scene.other_clickables.forEach((oc) => {
      if (
        !anyFulltour ||
        this.filterOtherClickables == '' ||
        this.filterOtherClickables == oc.materialID
      ) {
        const el: ClickableUI = new ClickableUI();
        el.clickable =Object.assign(new Clickable(), oc);

        el.onClick = () => {
          this.clickedOtherClickable(oc);
        };

        clickables.push(el);
      }
    });

    this.panoviewer.addClickableElements(clickables);
  }

  onConfigReady() {
    if (this.tour == undefined) return;

    const _scene = this.getScene(this.tour.startupscene);
    if (_scene != undefined) {
      this.gotoScene(this.tour.startupscene);
      this.panoviewer.setXY(_scene.startup_x, _scene.startup_y);
    }
  }

  clickedShortcut(shortcut: Shortcut) {
    this.gotoScene(shortcut.to);
    this.panoviewer.setXY(shortcut.view_x, shortcut.view_y);
  }

  clickedOtherClickable(clickable: Clickable) {
    this.clickableClicked.next(clickable);
  }

  setLoading(val: boolean) {
    this.loadingPano = val;
    this.ref.detectChanges();
  }

  // dev stuff

  initDev() {}

  DEV_logtour() {
    const json = JSON.stringify(this.tour.scenes);
    console.log(json);
  }

  DEV_addSwitch() {
    if (this.currentScene != undefined) {
      const sw = new Switch();
      sw.x = this.currentX;
      sw.y = this.currentY;

      this.currentScene.switches.push(sw);
      this.DEV_switchtoview(sw);
    }
  }

  DEV_applySwitch() {
    this.panoviewer.clearClickables();
    this.loadClickablesForScene(this.currentScene);
  }

  DEV_switchtoview(sw: Switch) {
    sw.x = this.currentX;
    sw.y = this.currentY;
    this.DEV_applySwitch();
  }

  DEV_setAsEntryRotationForAllSwitches() {
    if (this.tour == undefined) return;

    this.tour.scenes.forEach((el) => {
      el.switches.forEach((sw) => {
        if (sw.to == this.currentScene.id) {
          sw.view_x = this.currentX;
          sw.view_y = this.currentY;
        }
      });
    });
  }
}
