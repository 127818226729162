<div class="contact-button" 
[ngClass]="{'pano-page': isPano}"
*ngIf="showContactButton()"
[ngClass]="{'sidebar-offset': !fullframeSidebar && sidebarOpen}"
>
  <button class="contactbtn"
   (click)="clickedContactButton()">
    <span translate>contact.contact-button-text</span>
    <img class="contactusimg" src="./assets/ui/icons/contactus.png" *ngIf="!fullframeSidebar" />
    <img class="contactusimg" src="./assets/ui/icons/contactus-outline.svg"  *ngIf="fullframeSidebar" />
  </button>
</div>


<form class="form" *ngIf="contactFormOpen" [formGroup]="formGroup" (click)="bodyClick($event)" [ngClass]="{'force-errors': forceError}">
  <div class="container">
    <div class="header">
      <h2 translate>contact.form.header.title</h2>
      <span translate>contact.form.header.message</span>
    </div>

    <div class="content-columns">
      <div class="col contact-form">
        <div class="row fieldheader">
          <h3 translate>contact.form.message-header</h3>
        </div>

        <div class="row">
          <textarea formControlName="message" placeholder="{{'contact.form.fields.message' |translate}}"></textarea>
        </div>
        <div class="row fieldheader">
          <h3 translate>contact.form.fields-header</h3>
        </div>

        <div class="row">
          <input type="text" placeholder="{{'contact.form.fields.first_name' | translate}}" formControlName="first_name" />
          <input type="text" placeholder="{{'contact.form.fields.last_name' | translate}}" formControlName="last_name" />
        </div>
        <div class="row">
          <input type="text" placeholder="{{'contact.form.fields.company_name' | translate}}" formControlName="company_name" />
        </div>
        <div class="row">
          <input type="text" placeholder="{{'contact.form.fields.email' | translate}}" formControlName="email" />
        </div>
        <div class="row">
          <input type="text" placeholder="{{'contact.form.fields.phone_number' | translate}}" formControlName="phone_number" />
        </div>
        <div class="row">
          <input type="text" placeholder="{{'contact.form.fields.zip_code' | translate}}" formControlName="zip_code" />
          <input type="text" placeholder="{{'contact.form.fields.city' | translate}}" formControlName="city" />
        </div>
        <div class="row">
          <input type="text" placeholder="{{'contact.form.fields.country' | translate}}" formControlName="country" />
        </div>
        <div class="row">
          <div class="checkbox" (click)="toggle(formGroup.get('allow_marketing'))" [ngClass]="{'checked' : formGroup.get('allow_marketing').value}">
            <div class="box"></div>
            <span class="label" translate>contact.form.fields.marketing</span>
          </div>
        </div>

        <div class="row buttonrow">
          <button class="rounded green" (click)="clickedSendButton($event)"
           [ngClass]="{'busy' : busy}"
           >
            <span translate>
              contact.form.fields.send-button
            </span>
          </button>
        </div>
        <div class="row errorrow">
          <span class="error" *ngIf="formInvalidDueToRequired() && showRequiredError()" translate>contact.form.fields.errors.required</span>
          <span class="error" *ngIf="formInvalidDueToNoMailOrPhone() &&  showNoMailOrPhoneError()" translate>contact.form.fields.errors.either-phone-or-mail</span>
          <span class="error" *ngIf="formInvalidDueToIncorrectEmail() && showIncorrectMailError()" translate>contact.form.fields.errors.invalid-email</span>
        </div>

      </div>
      <div class="col links">

        <a target="_blank" href="{{'main.gea-dealer.button-url'|translate}}">
          
          <span translate>contact.form.links.dealer</span>
          <img src="./assets/ui/icons/icon-arrow-right-blue.svg">
        </a>
        <a target="_blank" href="{{'contact.form.links.contact-url'|translate}}">
          <span translate>contact.form.links.contact</span>
          <img src="./assets/ui/icons/icon-arrow-right-blue.svg">
          
        </a>

        </div>
    </div>
    
    <a class="close-button button transparant" (click)="clickedClose()">
      <img src="assets/ui/icons/icon-x-black.svg" />
    </a>
  </div>

  <div class="confirmation" *ngIf="showConfirm">
    <div class="popup" (click)="clickedConfirm()">
    <span translate>contact.confirm</span>

    <a class="close-button button transparant" (click)="clickedConfirm()">
      <img src="assets/ui/icons/icon-x-black.svg" />
    </a>
  </div>
  </div>

</form>