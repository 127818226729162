import { AfterViewInit, OnChanges } from '@angular/core';
import { HostListener } from '@angular/core';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import panzoom from 'panzoom';

export interface Restrictions {
  min_zoom: number;
  diagonal: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface Position {
  x: number;
  y: number;
}

export interface Margin {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

@Component({
  selector: 'app-panner',
  template: '<ng-content></ng-content>',
  styles: [':host{overscroll-behavior:none;}'],
})
export class PannerComponent implements OnInit, OnDestroy, AfterViewInit {
  //locals
  container: ElementRef;
  container_html: HTMLElement;
  @Input('content') content: HTMLElement;
  bounds: Restrictions = { min_zoom: 0.5, diagonal: 0 };
  content_size: Size = { width: 0, height: 0 };
  container_size: Size = { width: 0, height: 0 };

  //current values
  current_zoom: number = 1;
  current_position: Position = { x: 0, y: 0 };

  //wanted values
  wanted_position: Position = { x: 0, y: 0 };
  wanted_zoom: number = 1;

  //restrictions
  @Input('max-zoom') max_zoom: number = 5;
  @Input('enabled') enabled: boolean = true;
  @Input('start-zoom') start_zoom: number = 0.5;
  @Input('margin') margin: Margin = { left: 0, right: 0, top: 0, bottom: 0 };
  @Input('start-pos') startpos: 'center' | 'left' = 'center';

  //speeds
  zoom_speed_touch: number = 2;
  zoom_speed_scroll: number = 1 / 2000;
  click_speed_ms: number = 300;

  //mouse down up cached values
  down_position: Position = { x: 0, y: 0 };
  down_time: number;
  down: Boolean;
  pinch: Boolean;
  pinch_distance: number;

  //calculation values
  calculation_center: Position = { x: 0, y: 0 };

  //          PUBLICS

  //#region  publics

  public zoom_to(newZoom: number) {
    //clamp
    if (newZoom < this.bounds.min_zoom) newZoom = this.bounds.min_zoom;
    if (newZoom > this.max_zoom) newZoom = this.max_zoom;

    this.wanted_zoom = newZoom;

    //because we don't want to scale from the top-left point, but from the center of the visible area,
    //we'll need to adjust the x & y position as well - to keep the center point in center
    //currentpos = left-top pivot
    const scaledSize = {
      width: this.content_size.width * this.current_zoom,
      height: this.content_size.height * this.current_zoom,
    };
    const scaledPos = {
      x: this.current_position.x * this.current_zoom,
      y: this.current_position.y * this.current_zoom,
    };
    const centerPerc = {
      x: (scaledPos.x + 0.5 * this.container_size.width) / scaledSize.width,
      y: (scaledPos.y + 0.5 * this.container_size.height) / scaledSize.height,
    };
    const newScaledSize = {
      width: this.content_size.width * this.wanted_zoom,
      height: this.content_size.height * this.wanted_zoom,
    };
    const scaledCenter = {
      x: centerPerc.x * newScaledSize.width,
      y: centerPerc.y * newScaledSize.height,
    };
    const scaledCorner = {
      x: scaledCenter.x - 0.5 * this.container_size.width,
      y: scaledCenter.y - 0.5 * this.container_size.height,
    };
    const newCorner = {
      x: scaledCorner.x / this.wanted_zoom,
      y: scaledCorner.y / this.wanted_zoom,
    };

    this.wanted_position.x = newCorner.x;
    this.wanted_position.y = newCorner.y;

    this.apply();
  }

  public reset() {
    //easy way out: instead of calculated wanted center position based on start scale:
    //zoom to 1 to have 1 of the sides already centered
    //then center both edges (1 will be out of bounds, but will be clamped during movement)
    //then zoom in on that point to the start-zoom

    //zoom to 1 will center on the shortest edge
    this.zoom_to(1);

    if (this.startpos == 'center') {
      //move to center at zoom 1
      this.move_to(
        0.5 * this.content_size.width - 0.5 * this.container_size.width,
        0.5 * this.content_size.height - 0.5 * this.container_size.height
      );
    } else if (this.startpos == 'left') {
      this.move_to(
        0,
        0.5 * this.content_size.height - 0.5 * this.container_size.height
      );
    }

    //apply margin
    this.move_to(
      this.current_position.x + (-this.margin.left + this.margin.right) / 2,
      this.current_position.y + (-this.margin.top + this.margin.bottom) / 2
    );

    //apply wanted zoomlevel
    this.zoom_to(this.start_zoom);

    if (this.startpos == 'left') {
      this.move_to(0, this.current_position.y);
    }
  }

  public move_to_percentage(x:number, y:number){
    this.move_to(
      x * this.content_size.width - 0.5 * this.container_size.width,
      y * this.content_size.height - 0.5 * this.container_size.height
    );
  }

  public move_to(x: number, y: number) {
    this.wanted_position.x = x;
    this.wanted_position.y = y;

    this.apply();
  }

  //#endregion publics

  //          INIT

  constructor(container: ElementRef) {
    this.container = container;
    this.container_html = <HTMLElement>this.container.nativeElement;
    (<any>window).pannerlog = this.devlog.bind(this);
  }

  devlog() {
    console.log(
      'Current: ' +
        JSON.stringify(this.current_position) +
        ', ' +
        this.current_zoom
    );
    console.log(this.content.style.transform);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.removeEventListeners();
  }

  ngAfterViewInit() {
    this.listenForEvents();

    this.calculateBounds();
    this.calculateMinZoom();

    this.reset();
  }

  listenForEvents() {
    this.container_html.addEventListener(
      'touchstart',
      this.touch_down.bind(this)
    );
    this.container_html.addEventListener(
      'mousedown',
      this.mouse_down.bind(this)
    );
    this.container_html.addEventListener(
      'touchmove',
      this.touch_move.bind(this)
    );
    this.container_html.addEventListener(
      'mousemove',
      this.mouse_move.bind(this)
    );
    this.container_html.addEventListener('touchend', this.touch_end.bind(this));
    this.container_html.addEventListener('mouseup', this.mouse_up.bind(this));

    this.container_html.addEventListener(
      'mouseenter',
      this.mouse_enter.bind(this)
    );
    this.container_html.addEventListener(
      'mouseleave',
      this.mouse_leave.bind(this)
    );
    this.container_html.addEventListener('wheel', this.wheel.bind(this));
  }

  removeEventListeners() {
    this.container_html.removeEventListener(
      'touchstart',
      this.touch_down.bind(this)
    );
    this.container_html.removeEventListener(
      'mousedown',
      this.mouse_down.bind(this)
    );
    this.container_html.removeEventListener(
      'touchmove',
      this.touch_move.bind(this)
    );
    this.container_html.removeEventListener(
      'mousemove',
      this.mouse_move.bind(this)
    );
    this.container_html.removeEventListener(
      'touchend',
      this.touch_end.bind(this)
    );
    this.container_html.removeEventListener(
      'mouseup',
      this.mouse_up.bind(this)
    );

    this.container_html.removeEventListener(
      'mouseenter',
      this.mouse_enter.bind(this)
    );
    this.container_html.removeEventListener(
      'mouseleave',
      this.mouse_leave.bind(this)
    );
    this.container_html.removeEventListener('wheel', this.wheel.bind(this));
  }

  //          ACTUAL DOM THINGIES

  apply() {
    this.applyBounds();
    //TEMP - smoothing?
    this.current_zoom = this.wanted_zoom;
    this.current_position.x = this.wanted_position.x;
    this.current_position.y = this.wanted_position.y;

    this.content.style.transformOrigin = '0 0 0';
    const matrix =
      'matrix(' +
      this.current_zoom +
      ', 0, 0, ' +
      this.current_zoom +
      ', ' +
      -this.current_position.x * this.current_zoom +
      ', ' +
      -this.current_position.y * this.current_zoom +
      ')';
    this.content.style.transform = matrix;
  }

  //adjusts the wanted values to make the content fit in the container, without whitespace
  applyBounds() {
    //left and top bounds
    if (this.wanted_position.x < 0) this.wanted_position.x = 0;
    if (this.wanted_position.y < 0) this.wanted_position.y = 0;

    const xMax =
      this.content_size.width - this.container_size.width / this.wanted_zoom;
    const yMax =
      this.content_size.height - this.container_size.height / this.wanted_zoom;

    if (this.wanted_position.x > xMax) this.wanted_position.x = xMax;
    if (this.wanted_position.y > yMax) this.wanted_position.y = yMax;
  }

  //          RESTRICTION CALCULATION

  calculateBounds() {
    //container
    let hostEl = <HTMLElement>this.container.nativeElement;
    const containerWidth = hostEl.offsetWidth;
    const containerHeight = hostEl.offsetHeight;

    //content
    const contentWidth = this.content.offsetWidth;
    const contentHeight = this.content.offsetHeight;

    this.content_size.width = contentWidth;
    this.content_size.height = contentHeight;

    this.container_size.width = containerWidth;
    this.container_size.height = containerHeight;

    // this.bounds.diagonal = containerWidth / containerHeight;
    this.bounds.diagonal = Math.sqrt(
      containerHeight * containerHeight + containerWidth * containerHeight
    );
  }

  calculateMinZoom() {
    //minimum zoom is the zoomlevel where we're in
    // COVER mode, so background is not visible, and 1 axis can't pan
    let scaleX = this.container_size.width / this.content_size.width;
    let scaleY = this.container_size.height / this.content_size.height;

    this.bounds.min_zoom = Math.max(scaleX, scaleY);

    if (this.current_zoom < this.bounds.min_zoom)
      this.zoom_to(this.bounds.min_zoom);
  }

  //        EVENTS

  touch_down(e: TouchEvent) {
    if (e.touches.length == 2) {
      //multitouch = pinch
      this.pinch = true;
      this.down = false;
      this.pinch_distance = Math.hypot(
        e.touches[0].pageX - e.touches[1].pageX,
        e.touches[0].pageY - e.touches[1].pageY
      );
    } else if (e.touches.length == 1) {
      this.down = true;
      this.down_position.x = e.touches[0].pageX;
      this.down_position.y = e.touches[0].pageY;
    }
  }
  touch_move(e: TouchEvent) {
    if (this.down && !this.pinch) {
      this.move_delta(
        this.down_position.x - e.touches[0].pageX,
        this.down_position.y - e.touches[0].pageY
      );
      this.down_position.x = e.touches[0].pageX;
      this.down_position.y = e.touches[0].pageY;
    } else if (this.pinch) {
      const distance = Math.hypot(
        e.touches[0].pageX - e.touches[1].pageX,
        e.touches[0].pageY - e.touches[1].pageY
      );
      const delta = distance - this.pinch_distance;

      this.zoom_to(
        this.current_zoom +
          (delta / this.bounds.diagonal) * this.zoom_speed_touch
      );
      this.pinch_distance = distance;
    }
  }
  touch_end(e: TouchEvent) {
    this.pinch = false;
    this.down = false;
  }
  mouse_down(e: MouseEvent) {
    this.down = true;
    this.down_position.x = e.clientX;
    this.down_position.y = e.clientY;
  }
  mouse_move(e: MouseEvent) {
    if (this.down) {
      this.move_delta(
        this.down_position.x - e.clientX,
        this.down_position.y - e.clientY
      );
      this.down_position.x = e.clientX;
      this.down_position.y = e.clientY;
    }
  }
  mouse_up(e: MouseEvent) {
    this.down = false;
  }
  mouse_enter(e: MouseEvent) {}
  mouse_leave(e: MouseEvent) {
    this.pinch = false;
    this.down = false;
  }
  wheel(e: WheelEvent) {
    this.zoom_to(this.current_zoom - e.deltaY * this.zoom_speed_scroll);
  }

  move_delta(x: number, y: number) {
    this.move_to(
      this.current_position.x + x / this.current_zoom,
      this.current_position.y + y / this.current_zoom
    );
  }

  @HostListener('window:resize', ['e'])
  onResize(event?) {
    this.calculateBounds();
    this.calculateMinZoom();
  }
}
