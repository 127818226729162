import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';

import { easing } from 'ts-easing';

export const HEADER_HEIGHT : number = 0;

//export declare type EasingLogic = (t: number, b: number, c: number, d: number) => number;
export function doEase(currentTime: number, startY: number, offsetY: number, duration: number): number {
  const perc = currentTime / duration;

  const easedPerc = easing.inOutQuad(perc);

  return startY + (easedPerc * offsetY);
}

@Injectable({
  providedIn: 'root'
})
export class ScrollerService {

  constructor(private scroller: PageScrollService, @Inject(DOCUMENT) private document: any) { }


  public scrolltotop(){
    this.scroll("#top", 0, false);
  }

  public scroll(to: string, delay: number = 0, applyHeaderOffset: boolean = true) {

    if (delay == 0)
      this.scrollDirect(to, applyHeaderOffset);
    else {

      setTimeout(() => {
        this.scrollDirect(to, applyHeaderOffset);
      }, delay);

    }
  }

  public scrollDirect(to: string, applyHeaderOffset: boolean) {
    let offset = 0;
    //when going up, add offset of 80
    const inst = this.scroller.create({
      document: this.document,
      scrollTarget: to,
      easingLogic: doEase
    });


    if (applyHeaderOffset) {

      //shameless copy paste from the ngx module
      var startScrollPositionFound = false;

      if (inst.pageScrollOptions.scrollViews != undefined) {
        var scrollRange = inst.getScrollClientPropertyValue(inst.pageScrollOptions.scrollViews[0]);
        // Reset start scroll position to 0. If any of the scrollViews has a different one, it will be extracted next
        inst.startScrollPosition = 0;
        // Get the start scroll position from the scrollViews (e.g. if the user already scrolled down the content)
        inst.pageScrollOptions.scrollViews.forEach(function (scrollingView) {
          if (scrollingView === undefined || scrollingView === null) {
            return;
          }
          // Get the scrollTop or scrollLeft value of the first scrollingView that returns a value for its "scrollTop"
          // or "scrollLeft" property that is not undefined and unequal to 0
          var scrollPosition = inst.getScrollPropertyValue(scrollingView);
          if (!startScrollPositionFound && scrollPosition) {
            // We found a scrollingView that does not have scrollTop or scrollLeft 0
            // Return the scroll position value, as this will be our startScrollPosition
            inst.startScrollPosition = scrollPosition;
            startScrollPositionFound = true;
            // Remember te scrollRange of this scrollingView
            scrollRange = inst.getScrollClientPropertyValue(scrollingView);
          }
        });
      }
      var scrollTargetPosition = inst.extractScrollTargetPosition();

      // going up
      if (scrollTargetPosition.top < inst.startScrollPosition) {
        offset = HEADER_HEIGHT;
      }

    }else{
      offset = HEADER_HEIGHT;

    }
    
    inst.pageScrollOptions.scrollOffset = offset;
    this.scroller.start(inst);
  }
}
