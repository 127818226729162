import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ɵNG_ELEMENT_ID } from '@angular/core';

@Directive({
  selector: '[appFadeVisible]'
})
export class FadeVisibleDirective implements OnChanges {

  @Input("appFadeVisible") visible: boolean = false;
  @Input("fade-in-time") fadeInDuration: number = 0.25;
  @Input("fade-out-time") fadeOutDuration: number = 0.25;

  _el?: HTMLElement = undefined;

  constructor(private el: ElementRef) {
    this._el = <HTMLElement>this.el.nativeElement;
  }

  add(s: string): void {
    if (!this._el?.classList.contains(s))
      this._el?.classList.add(s);
  }

  remove(s: string): void {
    if (this._el?.classList.contains(s))
      this._el?.classList.remove(s);
  }

  has(s: string): boolean {
    if (this._el == undefined)
      return false;

    return this._el?.classList.contains(s);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.visible === true) {
      this.show();
    } else {
      this.hide();
    }
  }

  transitionID?: number;

  to(opacity: number, duration: number) {

    if (this._el == undefined)
      return;

    //animation specifics
    const direction = opacity == 1 ? 1 : -1;
    const frametime = 1 / 30;
    const deltaAlpha = 1 / (duration / frametime) * direction;

    //new state
    let newAlpha = +this._el.style.opacity + deltaAlpha;

    //clamp new state
    if (newAlpha > 1)
      newAlpha = 1;
    if (newAlpha < 0)
      newAlpha = 0;

    this._el.style.opacity = newAlpha.toString();

    if (newAlpha != opacity) {
      this.transitionID = window.setTimeout(() => { this.to(opacity, duration) }, frametime * 1000);

    } else {
      window.clearTimeout(this.transitionID);
      this.transitionID = undefined;

      if (opacity == 0) {
        this.add("hidden");
      }
    }
  }

  show() {

    if (this.has("hidden"))
      this.remove("hidden");

    if (this.transitionID != undefined) {
      window.clearTimeout(this.transitionID);
      this.transitionID = undefined;
    }

    this.to(1, this.fadeInDuration);
  }

  hide() {

    if (this.transitionID != undefined) {
      window.clearTimeout(this.transitionID);
      this.transitionID = undefined;
    }

    this.to(0, this.fadeOutDuration);
  }
}
