import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(private router : Router) {
   }

  sidebarOpenOnInit : boolean = true;
  public navigate(url : string[], sidebaropen : boolean){
    this.sidebarOpenOnInit = sidebaropen;
    this.router.navigate(url);
  }
}
