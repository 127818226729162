import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { Farm } from 'src/app/models/farm'
import { FarmService } from 'src/app/services/farm.service'
import { filter } from 'rxjs/operators'

import  packageInfo  from '../../../../package.json'
import { SidebarComponent } from 'src/app/shared/sidebar/sidebar.component'
import { DropdownData } from 'src/app/shared/ui-dropdown/ui-dropdown.component'
import { PanoRouteComponent } from 'src/app/pages/pano-route/pano-route.component'
import { AnalyticsService } from 'src/app/services/analytics.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-app-base',
  templateUrl: './app-base.component.html',
  styleUrls: ['./app-base.component.scss'],
})
export class AppBaseComponent implements OnInit, OnDestroy {
  private STORAGE_KEY_FARMS = 'farmAck'
  private STORAGE_KEY_PANO = 'panoAck'

  version: string = packageInfo.version

  @ViewChild('farmloaddata', { static: true }) farmloaddata: ElementRef
  @ViewChild('farmloaddata', { static: true }) panoloaddata: ElementRef

  @ViewChild('sidebar', { static: false }) sidebar: SidebarComponent

  private farmSubscription: Subscription = undefined

  farmLoading: boolean = false
  farmAcknowledged: boolean = false
  farmLoaded: boolean = false

  panoLoading: boolean = false
  panoLoaded: boolean = false
  panoAcknowledged: boolean = false

  ready: boolean = false

  progress: number = 0

  preloadList: string[] = []
  preloadQueue: string[] = []

  navSub: Subscription = undefined
  routeSub: Subscription = undefined

  translationsLoaded:boolean = false;

  url: string

  needsConsent : boolean;

  constructor(
    private translate: TranslateService,
    private farmService: FarmService,
    private router: Router,
    private route: ActivatedRoute,
    private analytics : AnalyticsService
  ) {
    this.needsConsent = false;
    this.farmSubscription = this.farmService.farms.subscribe((result) => {
      this.farmsReady(result)
    })

    translate.get('init').subscribe((_) => {
      this.translationsLoaded = true;
    });

    if(!this.analytics.hasUserAnsweredConsentPopup()){
      this.needsConsent = true;
    }else if(this.analytics.hasUserConsentedToTracking()){
      this.startAnalytics();
    }

    // console.log("DEV!!! SKIP START SCREEN - REMOVE HERE")
    // this.farmAcknowledged = true;


    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(environment.default_language)
    let startupLanguage = environment.default_language;

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const saved = localStorage.getItem(environment.sessionkey_language)
    if (saved != '' && saved != null) 
      startupLanguage = saved
      translate.use(startupLanguage)

      this.farmAcknowledged = false;
      this.panoAcknowledged = false;
  }

  onCookieSelectionMade(){
    this.needsConsent = false;
    if(this.analytics.hasUserConsentedToTracking()){
      this.startAnalytics();
    }
  }
  

  startAnalytics(){
    this.analytics.init();
  }

  getVersionTranslation() : string{
    const id = "main.version-number";
    let  translated = <string>this.translate.instant(id);
    translated = translated.replace("1.0", this.version);

    return translated;
  }


  ngOnDestroy(): void {
    if (this.farmSubscription != undefined) this.farmSubscription.unsubscribe()
    if (this.navSub != undefined) this.navSub.unsubscribe()
    if (this.routeSub != undefined) this.routeSub.unsubscribe()
  }

  ngOnInit(): void {
    this.ready = false
    this.navSub = this.router.events.subscribe((event) => {
      this.routerEvent(event)
    })
  }

  private routerEvent(event) {
    if (event instanceof NavigationEnd) {
      this.preload(event.url)
    }
  }

  clickedAcknowledged() {
    this.farmAcknowledged = true
    this.ready = true
    sessionStorage.setItem(this.STORAGE_KEY_FARMS, 'true')
  }

  closeSidebar() {
    this.sidebar.toggle()
  }

  //                PRELOADING FLOW

  fetchFarmlist() {
    this.farmService.init()
  }

  farmsReady(farms: Farm[]) {
    if (farms == undefined) return


    if (this.farmLoading) this.preloadFarms()
    else if (this.panoLoading) this.preloadPanos()
  }

  preload(url: string) {
    const isPano = url.indexOf('pano') > -1
    this.url = url

    if (isPano && !this.panoLoaded && !this.panoLoading) {
      this.ready = false

      this.panoLoading = true
      this.farmLoading = false
      this.farmAcknowledged = true;

      this.progress = 0

      this.panoAcknowledged = true
      sessionStorage.setItem(this.STORAGE_KEY_PANO, 'true')
      //END HACK
      this.fetchFarmlist()
    }
    if (!isPano && !this.farmLoaded && !this.farmLoading) {
      this.ready = false

      this.panoLoading = false
      this.farmLoading = true

      this.progress = 0
      this.fetchFarmlist()
    }
  }

  preloadPanos() {
    let images: string[] = []

    //fetch id from url

    //remove pano
    let url = this.url.replace('/pano', '')

    //remove possible trailing slash
    if (url.endsWith('/')) url = url.substr(0, url.length - 1)

    //fetch last part after slash
    let index = url.lastIndexOf('/')
    url = url.substr(index + 1)

    //find farm
    const farm = this.farmService.getFarms().find((x) => x.id == url)

    if (farm == undefined) {
      console.error('Could not find pano with id ' + url)
    } else {
      farm.farmTour.scenes.forEach((pano) => {
        images.push(pano.filename)
      })

      images.push("./assets/data/marcus/talking.webp");
      images.push("./assets/data/marcus/idle.webp");
    }

    this.loadImages(images, this.panoloaddata)
  }

  preloadFarms() {
    console.log('preload farms')

    let images: string[] = []
    const farms = this.farmService.getFarms()
    farms.forEach((farm) => {
      images.push(farm.render_drone)
      images.push(farm.render_floorplan)
      // images.push(farm.route_image)
    })

    this.loadImages(images, this.farmloaddata)
  }

  loadImages(images: string[], target: ElementRef) {
    this.preloadQueue = Object.assign([], images)
    this.preloadList = Object.assign([], images)
    this.loadNextImage(target)
  }

  loadNextImage(target: ElementRef) {
    //update progress
    const toLoad = this.preloadQueue.length
    const total = this.preloadList.length
    this.progress = (total - toLoad) / total

    //check if we're done
    if (this.preloadQueue.length == 0) {
      this.doneLoadingImages()
      return
    }
    //take 1 from the queue and remove it from the queue
    let image = this.preloadQueue.shift()
    if(this.farmService.shouldLoadLQFiles){
      if(image.indexOf("_mobile") == -1 && image.indexOf("marcus") == -1)
      image = image.replace(".jpg", "_mobile.jpg");
    }

    let i = new Image()
    i.src = image

    const parent = <HTMLElement>target.nativeElement

    parent.appendChild(i)

    i.onload = () => {
      console.log("Done loading " + i.src);
      this.loadNextImage(target)
      // fake timeout for dev purposes
      // setTimeout(() => {
      //   this.loadNextImage(target);
      // }, 2000);
    }
  }

  doneLoadingImages() {
    console.log('done preloading!')
    if (this.farmLoading) {
      this.farmLoading = false
      this.farmLoaded = true
      this.farmAcknowledged = true;

      if (this.farmAcknowledged) this.ready = true
    } else if (this.panoLoading) {
      // console.log("done panoloading - DEV disabled continue to test stuff in loading window :P");
      this.panoLoading = false
      this.panoLoaded = true
      
      if (this.panoAcknowledged) this.ready = true
    }
  }
}
