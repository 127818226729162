<!-- drag image -->
<app-panner [content]="content" #panner class="full-frame container"  start-zoom="-1" [max-zoom]="isPhone?1:2" 
[start-pos]="isPhone ? 'center' : 'left'" >
    <div class="content" #content [ngStyle]="image_container_style"
    
    >
    <div class="route-image" [style.background-image]="'url(\''+farm.route_image + '_' + currentFilter + '.svg\')'">

    </div>

        <ng-container *ngFor="let point of farm.route_infopoints">
            <div class="route-point" *ngIf="shouldPointBeVisible(point)"
                [style.left]="point.point_position.x" [style.top]="point.point_position.y">
                <div class="point bg alpha" [ngClass]="getPointClass(point)" 
                (click)="startRouteAtPoint(point.panoid)"
                >
                <!-- (click)="togglePopup(point.number)" -->
                    {{point.number}}
                </div>
                <div class="ring"></div>
                <div class="ring2"></div>
            </div>
        </ng-container>

    </div>

</app-panner>

<app-sidebar class="sidebar" stretch="true" can-close="false" [start-open]="sidebarStartOpen" (state-changed)="onSidebarStateChange($event)">
    <h2 translate>route-selection.sidebar-header</h2>

    <div class="flow">
        <div class="filter" [ngClass]="{'open': isFilterOpen('fulltour')}">
            <div class="header" (click)="setFilter('fulltour')">
                <div class="icon bg all">
                    <img [src]="'./assets/ui/icons/icon-all.svg'" />
                </div>
                <span class="label" translate="filters.all.title"></span>
            </div>
        </div>

        <ng-container *ngFor="let filter of FILTERS" >
        <div class="filter" *ngIf="shouldShowFilter(filter.id)" [ngClass]="{'open': isFilterOpen(filter.id)}">
            <div class="header" (click)="setFilter(filter.id)">
                <div class="icon bg" [ngClass]="filter.color">
                    <img [src]="'./assets/ui/icons/icon-' + filter.icon + '.svg'" />
                </div>
                <span class="label" [translate]="filter.title"></span>
            </div>
            <div class="desc" [innerHTML]="filter.desc | translate">
            </div>
        </div>
        
    </ng-container>
    </div>

    <div class="action-row stretch">
        <a class="rounded large tertiarybutton" (click)="clickedBackInSidebar()">
            <img src="./assets/ui/icons/icon-arrow-left-white.svg">
            <span>
                {{'main.navigation.backwards.overview' | translate}}
            </span>
        </a>
        <a class="rounded large green" (click)="clickedStartTour()" [routerLink]="forwardUrl"  *ngIf="forwardTranslateID != '' && !isPhone">
            {{forwardTranslateID | translate}}
            <img src="./assets/ui/icons/icon-arrow-right-black.svg">
        </a>
        <a class="rounded large green" (click)="sidebar.close()" *ngIf="isPhone">
            <span translate>
                main.navigation.mobile.view-route-on-map
            </span>
            <img src="./assets/ui/icons/icon-arrow-right-black.svg">
        </a>
    </div>
</app-sidebar>

<img class="gea-logo" src="./assets/ui/logos/gea-logo-payoff-white.svg" *ngIf="!isPhone">
<img class="gea-logo" src="./assets/ui/logos/gea-logo-no-payoff.svg" *ngIf="isPhone">


<!-- [overrideForwardTranslation]="forwardTranslateID"  -->
<app-navigatorbar [bar-open]="sidebarOpen" 
backTranslate="{{isPhone ? 'main.navigation.backwards.routes':'main.navigation.backwards.overview'}}"
forwardTranslate="main.navigation.forward.pano"
(backClicked)="backClickedNavigator()"
(forwardClicked)="forwardClickedNavigator()">
></app-navigatorbar>

  