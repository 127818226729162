<div class="tile" *ngIf="data != undefined" id="tile" #tile>
    <a class="back-button pointer" (click)="clickedClose()">
        <img src="./assets/ui/icons/icon-arrow-left-white.svg">
        <span translate>buttons.shared.back</span>
    </a>

    <div class="title" [innerHTML]="getTileTranslation('info-title')" id="infotile-title"></div>

    <div class="content">

        <div class="usplist usp-left" [ngClass]="{'hidden': specialMarcus}">
            <ng-container *ngFor="let usp of uspindexleft">
                <div class="usp-line" *ngIf="getUspTranslation(usp, 'title') != ''" [innerHTML]="getUspTranslation(usp, 'title') "
                (click)="selectUsp(usp)" [ngClass]="{'active': usp == selectedUspIndex}"></div>
                <!-- (mouseenter)="selectUsp(usp)"  -->
            </ng-container>
        </div>
        <div class="usp-content">
            <ng-container *ngIf="selectedUspData != undefined">

                <div class="media" [ngSwitch]="selectedUspData.mediatype" [ngClass]="{'hidden': specialMarcus}">

                    <div class="thumb" [ngClass]="selectedUspData.class" *ngSwitchCase="'image'"
                        [style.backgroundImage]="'url(\'./assets/data/infotile-content/images/'+selectedUspData.thumb+'\')'">
                    </div>
                    <div class="thumb pointer" [ngClass]="selectedUspData.class" *ngSwitchCase="'video'"
                        [style.backgroundImage]="'url(\'./assets/data/infotile-content/images/'+selectedUspData.thumb+'\')'"
                        (click)="clickedPlayVideo(selectedUspData.media)">
                    </div>
                    <div class="thumb pointer" [ngClass]="selectedUspData.class" *ngSwitchCase="'pdf'"
                        [style.backgroundImage]="'url(\'./assets/data/infotile-content/images/'+selectedUspData.thumb+'\')'"
                        (click)="clickedPlayPDF(selectedUspData.media)">

                    </div>
                    <ng-container *ngSwitchCase="'3d'">

                        <letink-three-sixty-slider [ngClass]="{'grab': !dragging, 'grabbing': dragging}"
                            container-class="contain" #slider [config]="config" [autoInit]="false"
                            (onLoadingPercentageChange)="slider_preload_percentage = $event"
                            (onImagesLoaded)="onImagesLoaded($event)" (onDownStateChange)="onDownChange($event)">
                        </letink-three-sixty-slider>
                        
                        <div class="loading" [appFadeVisible]="!images_loaded">
                            <svg fill="none" viewBox="0 0 24 24" [ngStyle]="{ 'width': size, 'height': size}">
                                <circle cx="12" cy="12" r="10" fill="none" stroke-width="3" stroke-miterlimit="10"
                                    filter="url(#shadow)"></circle>
                                <circle cx="12" cy="12" r="10" fill="none" stroke-width="3" stroke-miterlimit="10">
                                </circle>

                            </svg>
                            <span>
                                {{slider_preload_percentage | percent}}
                            </span>
                        </div>


                    </ng-container>

                </div>
                <div class="usp-title" *ngIf="isPhone">
                    {{selectedUspData.title}}
                </div>
                <div class="desc" [innerHTML]="selectedUspData.desc" [ngClass]="{'hidden': specialMarcus}"></div>

                <!-- more info: regular url -->
                <div class="moreinfo" *ngIf="selectedUspData.url != '' && selectedUspData.url != 'gea-dealer'" [ngClass]="{'hidden': specialMarcus}">

                    <a [href]="selectedUspData.url" target="_blank" class="rounded green large" translate>
                        buttons.shared.moreinfo
                    </a>
                </div>
                <!-- more info: gea dealer -->
                <div class="moreinfo" *ngIf="selectedUspData.url == 'gea-dealer'"  [ngClass]="{'hidden': specialMarcus}">
                    <a target="blank" (click)="clickedGeaDealer()"class="rounded green large" translate>
                        main.gea-dealer.button-text
                    </a>
                </div>
                
                <!-- more info: video -->
                <div class="moreinfo" *ngIf="selectedUspData.mediatype == 'video' && selectedUspData.media != ''"  [ngClass]="{'hidden': specialMarcus}">
                    <a target="blank" (click)="clickedPlayVideo(selectedUspData.media)" class="rounded green large" translate>
                        buttons.shared.moreinfo
                    </a>
                </div>
                <!-- more info: pdf -->
                <div class="moreinfo" *ngIf="selectedUspData.mediatype == 'pdf' && selectedUspData.media != ''"  [ngClass]="{'hidden': specialMarcus}">
                    <a target="blank" (click)="clickedPlayPDF(selectedUspData.media)" class="rounded green large" translate>
                        buttons.shared.moreinfo
                    </a>
                </div>

                

            </ng-container>
        </div>
        <div class="usplist usp-right" [ngClass]="{'hidden': specialMarcus}">
            <ng-container *ngFor="let usp of uspindexright">
                <div class="usp-line" *ngIf="getUspTranslation(usp, 'title') != ''"
                    [innerHTML]="getUspTranslation(usp, 'title') " (click)="selectUsp(usp)"
                    [ngClass]="{'active': usp == selectedUspIndex}"></div>
                    <!-- (mouseenter)="selectUsp(usp)"  -->
            </ng-container>
        </div>
    </div>

    <div class="overlay video" [appFadeVisible]="focus_video != ''">
        <app-video *ngIf="focus_video != ''" [videopath]="focus_video" [autoplay]="true" [muted]="true"
            [controls]="true" mode="contain"></app-video>

        <div class="close-button" (click)="closeFocus()">
            <img src="./assets/ui/icons/icon-x.svg">
        </div>
    </div>


    <div class="overlay pdf" [appFadeVisible]="focus_pdf != undefined">
        <iframe [attr.src]="focus_pdf" type="application/pdf" *ngIf="focus_pdf != undefined"></iframe>

        <div class="close-button" (click)="closeFocus()">
            <img src="./assets/ui/icons/icon-x.svg">
        </div>
    </div>


    <div class="overlay link" [appFadeVisible]="focus_link != undefined">
        <iframe [attr.src]="focus_link" ></iframe>

        <div class="close-button" (click)="closeFocus()">
            <img src="./assets/ui/icons/icon-x.svg">
        </div>
    </div>

</div>