import { Injectable } from '@angular/core';
import { NgxGa4Service } from '@kattoshi/ngx-ga4';

// import { FirebaseApp, initializeApp } from 'firebase/app';
// import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';

// export const firebaseConfig = {
//   apiKey: 'AIzaSyBFc14bzYzkgfvJ9LOUkjnwBCEFe4NNHqM',
//   authDomain: 'gea-farm---web.firebaseapp.com',
//   projectId: 'gea-farm---web',
//   storageBucket: 'gea-farm---web.appspot.com',
//   messagingSenderId: '582402504036',
//   appId: '1:582402504036:web:d19054fb55dcff754d7671',
//   measurementId: 'G-4PPD35ZZS2',
// };

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  // app: FirebaseApp;
  // analytics: Analytics;

  constructor(
    private _ga4 : NgxGa4Service
  ) {}

  public async init() {
    console.log('init analytics');
    // this.app = initializeApp(firebaseConfig);
    // this.analytics = getAnalytics(this.app);
    try {
      await this._ga4.install$("G-DBBV8L44TY");
      this._ga4.js();
      this._ga4.config();
      this.send("app_start");

    }
    catch(ex)
    {
      throw new Error (`Script load exception=${ex}`);
    }
  }

  public hasUserConsentedToTracking(): boolean {
    const item = localStorage.getItem('gea-farm-tour-web-cookie-consent');
    if (item === null) return false;
    const parsed = <boolean>JSON.parse(item);
    return parsed;
  }
  
  public hasUserAnsweredConsentPopup(): boolean {
    const item = localStorage.getItem('gea-farm-tour-web-cookie-consent');
    return item != null;
  }

  public userConsentAction(consent : boolean){
    localStorage.setItem('gea-farm-tour-web-cookie-consent', JSON.stringify(consent));
  }

  public send(eventName: string, value: string | number = '') {
    if (this.hasUserConsentedToTracking()) {
      // logEvent(this.analytics, eventName, { value: value });
      
    this._ga4.event(eventName , 
    { 
      value : value
    });
    }
  }
}
