<a class="dropdown rounded" (click)="clickedDropdown()" [ngClass]="{'open': open}">
    <img *ngIf="value != null && value.icon" [src]="value.icon">
    <span class="selected-value" [translate]="value.label"></span>
    <img class="arrow" src="./assets/ui/icons/icon-dropdown-arrow.svg">
</a>
<div class="blocker" (click)="clickedBlocker()" *ngIf="open"></div>
<div class="dropdown-list" [ngClass]="{'open': open}">
    <a class="dropdown-item" *ngFor="let item of dropdownData" (click)="clickedValue(item)">
        <img *ngIf="item != null && item.icon" [src]="item.icon">
        <span class="selected-value" translate>{{item.label}}</span>
    </a>
</div>