import { Scene, Tour } from "../shared/panoSystem/models/tour";
import { PanoInfoTile } from "./pano-infotile";
import { RouteSelectorInfopoint } from "./route-selector-infopoint";

export class Farm {
    //ID is used as a string in the URL for routing purposes
    public id: string;

    //1 of the toggles in the farm selector
    public herdSize: number;
    //1 of the toggles in the farm selector. Value should be A, B or empty if there is no choice
    public layout: string;


    //this render is shown in the farm-selector
    public render_drone: string;

    //this render is shown in the route-selector and has a route overlay rendered into it
    public render_floorplan: string;

    //this is the focus image of the same farm. Only the POI are visible (or more visible i dunno)
    public route_image: string;

    //the infopoints displayed in the route-selector
    public route_infopoints: RouteSelectorInfopoint[];

    public voiceover_mapping : PanoVoiceoverMapping[];

    //the filename of the 360 route
    public pano_route_content: string;

    //downloaded per farm and assigened by farmService - actual content is not located in farm-data.json, but in separate file pano-route.json
    public farmTour: FarmTour;
}

export class PanoVoiceoverMapping{
    public sceneid : string;
    public voiceoverids : string[];
}

export class FarmTour extends Tour{
    public infotiles : PanoInfoTile[];
}

