import { SelectControlValueAccessor } from "@angular/forms";
import { ClickableUI, FontData, PanoViewerConfig } from "./pano-viewer-config";
import * as THREE from 'three';

export class Tour {
    public basicConfig: PanoViewerConfig;

    public startupscene: string;

    public shortcuts: Shortcut[] = [];

    public scenes: Scene[]

    public static build(data: any): Tour {

        const tour = Object.assign(new Tour(), data);

        tour.basicConfig = PanoViewerConfig.build(tour.basicConfig);
        for (let i = 0; i < tour.scenes.length; i++) {
            tour.scenes[i] = Scene.build(tour.scenes[i]);
        }

        for (let i = 0; i < tour.shortcuts.length; i++) {
            tour.shortcuts[i] = Shortcut.build(tour.shortcuts[i]);
        }

        return tour;
    }
}

export class Shortcut {
    public to: string = "";
    public title: string = "";
    public thumb: string = "";
    public view_x: number = 180;
    public view_y: number = 0;

    public static build(data: any): Shortcut {
        return Object.assign(new Shortcut(), data);
    }
}

export class Scene {
    public id: string;
    public filename: string;
    public other_clickables: Clickable[] = [];
    public switches: Switch[];
    
    public startup_x: number = 0;
    public startup_y: number = 0;

    public static build(data: any): Scene {
        const scene = Object.assign(new Scene, data);

        for (let i = 0; i < scene.other_clickables.length; i++) {
            scene.other_clickables[i] = Clickable.build(scene.other_clickables[i]);
        }

        for (let i = 0; i < scene.switches.length; i++) {
            scene.switches[i] = Switch.build(scene.switches[i]);
        }
        return scene;
    }
}

export class Clickable {
    public materialID: string;

    public id : string = "";

    public x: number = 0;
    public y: number = 0;
    public label_top: string = "";
    public label_center : string = "";
    public scale: number = 1;

    overrideFontdata? : FontData;
    
    public static build(data: any): Clickable {
        const sw = Object.assign(new Clickable, data);
        return sw;
    }
}


export class Switch extends Clickable {

    public to: string = "";
    public view_x: number = 180;
    public view_y: number = 0;

    public static build(data: any): Switch {
        const sw = Object.assign(new Switch, data);
        return sw;
    }
}
