<div class="bar" [ngClass]="{ open: state, closed: !state, animating: animating }">
  <div class="container" [ngClass]="{ stretch: stretch }">
    <!-- <img class="logo" src="./assets/ui/logos/gea-logo-payoff-white.svg"> -->
    <ng-content></ng-content>
  </div>
</div>
<div *ngIf="!state" class="menu-button" (click)="open()">
  <span class="label" translate>buttons.shared.menu</span>
  <img src="./assets/ui/icons/icon-menu.svg" />
</div>

<div *ngIf="state && canClose" class="menu-button close" (click)="close()">
  <!-- <span class="label" translate>main.menu</span> -->
  <img src="./assets/ui/icons/icon-x.svg" />
</div>